import { Content } from 'components/Layout';
import React from 'react';
import AdminSignIn from 'admin/AdminSignIn';
import Header from 'components/commons/header';
import Footer from 'components/commons/footer';

class AdminLoginLayout extends React.Component {
  render() {
    return (
      <main>
        <Content fluid>
          <Header />
          <AdminSignIn />
          <Footer />
        </Content>
      </main>
    );
  }
}

export default AdminLoginLayout;
