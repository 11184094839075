import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from '../components/commons/header';
import Home from './home';
import Committe from './committe';
import Members from './registeredMembers';
import Footer from '../components/commons/footer';
import Contact from './contact';
import Report20 from './annualReports/annualReport20';
import Report21 from './annualReports/annualReport21';
import NotFound from './NotFound';
import BilldeskPolicy from './BilldeskPolicy';
import './css/bootstrap.css';
import './css/royal-preload.css';
import './css/ionicon.css';
import './css/font-awesome.css';
import './css/slick.css';
import './css/ionicon.css';
import './css/slick-theme.css';
import './style.css';
import Transaction from "../user/pages/transaction"
class Landing extends React.Component {
  render() {
    return (
      <div id="page" className="site">
        <Header />
        <Router basename="/landing">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/committee" component={Committe} />
            <Route exact path="/members" component={Members} />
            <Route path="/contact-us" component={Contact} />
            <Route path="/billdesk-policy" component={BilldeskPolicy} />
            <Route path="/annual_report_20" component={Report20} />
            <Route path="/annual_report_21" component={Report21} />
            <Route
                exact
                  path="/transactions/:transactionId"
                  component={Transaction}
                  />
            <Route component={NotFound} />
          </Switch>
        </Router>
        <Footer />
      </div>
    );
  }
}

export default Landing;
