import React from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = e => {
    e.preventDefault();
    if (
      !this.state.mob ||
      (this.state.mob && this.state.mob.length !== 10) ||
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.query
    ) {
      try {
        this.notificationSystem.addNotification({
          title: 'Question',
          message:
            'Mobile no. should be 10 Digit, Email should valid and please enter other fields.',
          level: 'error',
        });
      } catch (err) {
        alert(
          'Mobile no. should be 10 Digit, Email should valid and please enter other fields.',
        );
      }
    } else {
      axios
        .post('/api/users/postHelpQuestion', {
          body: {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mob: this.state.mob,
            email: this.state.email,
            query: this.state.query,
          },
        })
        .then(response => {
          try {
            this.notificationSystem.addNotification({
              title: 'Question',
              message: 'Question sent to admin! Tune in for updates.',
              level: 'success',
            });
          } catch (err) {
            alert('Question sent to admin! Tune in for updates.');
          }
          this.setState({
            firstName: '',
            lastName: '',
            mob: '',
            email: '',
            query: '',
          });
        })
        .catch(err => {
          try {
            this.notificationSystem.addNotification({
              title: 'Question',
              message:
                'Error processing Question. Please ask the question again.',
              level: 'error',
            });
          } catch (err) {
            alert('Error processing Question. Please ask the question again.');
          }
        });
    }
  };

  render() {
    return (
      <section className="wpb_row row-fluid section-padd">
        <div className="container">
          <NotificationSystem
            dismissible={false}
            ref={notificationSystem =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
          <div className="row">
            <div className="wpb_column column_container col-sm-12 col-md-7">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="section-head ">
                    <h6>
                      <span>CONTACT FORM</span>
                    </h6>
                    <h2 className="section-title">How can we help</h2>
                  </div>

                  <div className="empty_space_12">
                    <span className="empty_space_inner"></span>
                  </div>
                  <div
                    role="form"
                    className="wpcf7"
                    id="wpcf7-f1989-p967-o1"
                    lang="en-US"
                    dir="ltr"
                    onSubmit={e => {
                      e.preventDefault();
                    }}
                  >
                    <div className="screen-reader-response"></div>
                    <form
                      className="wpcf7-form"
                      onSubmit={e => {
                        e.preventDefault();
                      }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-fname">
                            <input
                              type="text"
                              name="your-fname"
                              value={
                                this.state.firstName ? this.state.firstName : ''
                              }
                              size="40"
                              className="wpcf7-form-control"
                              required
                              placeholder="First Name"
                              onChange={e =>
                                this.setState({ firstName: e.target.value })
                              }
                            />
                          </span>
                        </div>
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-lname">
                            <input
                              type="text"
                              name="your-lname"
                              value={
                                this.state.lastName ? this.state.lastName : ''
                              }
                              size="40"
                              className="wpcf7-form-control"
                              required
                              placeholder="Last Name"
                              onChange={e =>
                                this.setState({ lastName: e.target.value })
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-phone">
                            <input
                              required
                              type="text"
                              name="your-phone"
                              pattern="\[6-9]\d{9}"
                              size="40"
                              className="wpcf7-form-control"
                              value={this.state.mob ? this.state.mob : ''}
                              placeholder="Phone Number"
                              onChange={e =>
                                this.setState({ mob: e.target.value })
                              }
                            />
                          </span>
                        </div>
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-email">
                            <input
                              type="email"
                              name="your-email"
                              required
                              size="40"
                              className="wpcf7-form-control"
                              placeholder="Email Address"
                              value={this.state.email ? this.state.email : ''}
                              onChange={e =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </span>
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-company">
                            <input
                              type="text"
                              name="your-company"
                              value=""
                              size="40"
                              className="wpcf7-form-control"
                              placeholder="Your Company"
                            />
                          </span>
                        </div>
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-subject">
                            <input
                              type="text"
                              name="your-subject"
                              value=""
                              size="40"
                              className="wpcf7-form-control"
                              placeholder="Subject"
                            />
                          </span>
                        </div>
                      </div> */}
                      <div className="contact-mess">
                        <span className="wpcf7-form-control-wrap your-message">
                          <textarea
                            name="your-message"
                            cols="40"
                            rows="10"
                            required
                            className="wpcf7-form-control"
                            placeholder="Add your Query here..."
                            value={this.state.query ? this.state.query : ''}
                            onChange={e =>
                              this.setState({ query: e.target.value })
                            }
                          ></textarea>
                        </span>
                      </div>
                      <p>
                        <Button
                          type="submit"
                          onClick={this.handleSubmit}
                          className="wpcf7-form-control wpcf7-submit btn"
                        >
                          Submit
                        </Button>
                      </p>
                    </form>
                  </div>
                  <div className="empty_space_30 lg-hidden">
                    <span className="empty_space_inner"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-5">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="wpb_single_image wpb_content_element align_center">
                    <figure className="wpb_wrapper figure">
                      <div className="single_image-wrapper box_border_grey">
                        {/* <img
                          src="https://via.placeholder.com/750x990"
                          className="single_image-img attachment-full"
                          alt=""
                        /> */}
                        <iframe
                          title="Google Maps"
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15134.307513056263!2d73.856539!3d18.5028155!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc0931d85a0bbfac6!2sIndian%20Medical%20Association%20Pune%20Maharashtra.!5e0!3m2!1sen!2sin!4v1581842947581!5m2!1sen!2sin"
                          width="990"
                          height="750"
                          frameborder="0"
                          style={{
                            border: '0',
                            width: '750px',
                            height: '500px',
                          }}
                          allowfullscreen=""
                        ></iframe>
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Form;
