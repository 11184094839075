import React from 'react';
import AskQue from './ask_question';

class FAQ extends React.Component {
  state = {
    showName: 'Show More',
    flag: false,
    formFlag: false,
  };
  showForm = () => {
    if (this.state.formFlag) {
      this.setState({ formFlag: false });
    } else {
      this.setState({ formFlag: true });
    }
  };
  showMoreButton = e => {
    e.preventDefault();
    if (this.state.flag) {
      this.setState(prev => ({
        flag: false,
        formFlag: false,
        showName: 'Show More',
      }));
    } else {
      this.setState(prev => ({
        flag: true,
        showName: 'Show Less',
      }));
    }
  };
  render() {
    return (
      <section
        className="accordion-section clearfix mt-3"
        aria-label="Question Accordions"
        id="FAQs"
      >
        <div className="container">
          <h2>Frequently Asked Questions </h2>
          <div
            className="panel-group"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
          >
            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading0">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse0"
                    aria-expanded="true"
                    aria-controls="collapse0"
                  >
                    1. Highlights of positive things.
                  </a>
                </h3>
              </div>
              <div
                id="collapse0"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading0"
              >
                <div className="panel-body px-3 mb-4">
                  <ul>
                    <li>
                      Any life member of IMA in Maharashtra below the age of 60
                      is eligible.
                    </li>
                    <li>
                      No medical examination needed, so no botheration of
                      pre-existing disease, added load etc.
                    </li>
                    <li>
                      Benefit amount keeps on increasing even after completing
                      your tenure of 25 installments after admission.
                    </li>
                    <li>Comradeship</li>
                    <li>
                      No provision of retirement or money back – the money goes
                      to nominee only upon death of the member, except in rare
                      circumstances of permanent major disability leading to
                      inability to practice like coma, quadriplegia, intractable
                      heart failure, terminal cancer etc.
                    </li>
                    <li>
                      Settlement of claim within 1 to 2 weeks (within 48 hours
                      after papers are received by the office), once the minimal
                      paper work is completed.
                    </li>
                    <li>Claim settlement ratio &gt; 99%.</li>
                    <li>
                      After 25 installments of fraternity contribution, one has
                      to pay only Rs. 200/- per year (This may change) as annual
                      fees and the death benefits go on increasing.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading_2">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_2"
                    aria-expanded="true"
                    aria-controls="collapse_2"
                  >
                    2. What is this scheme and how it works? Will it survive
                    after 25 years?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_2"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_"
              >
                <div className="panel-body px-3 mb-4">
                  <p>
                    This is a mutually beneficial scheme for IMA life members
                    where, member of the scheme contribute their share to the
                    diseased person’s family. The member pays the admission fee
                    depending on age and then makes 25 fraternity contributions
                    and later only annual maintenance fees. This scheme is
                    already 30 years old! And still functioning increasingly
                    well.
                  </p>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading_3">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_3"
                    aria-expanded="true"
                    aria-controls="collapse_3"
                  >
                    3. We go for short term investments, life is too fast. Why
                    should I lock my money for 25 years?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_3"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_3"
              >
                <div className="panel-body px-3 mb-4">
                  <p>
                    Please understand that life insurance or similar schemes are
                    always long term. This should not be misunderstood
                    forinvestment scheme like mutual funds, shares etc. it is
                    meant to support your family in crisis in a speedy manner.
                    And we feel, as consensus doctor, you value your family who
                    have helped us achieve what you are today!
                  </p>
                </div>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading_4">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_4"
                    aria-expanded="true"
                    aria-controls="collapse_4"
                  >
                    4. What is the membership strength as on 31st January 2020?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_4"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_4"
              >
                <div className="panel-body px-3 mb-4">
                  <p>
                    8051 members – 430 deaths from inception – deletions 833
                    since inception = 6788. By April 2020, a total of 607
                    members would retire and then they will contribute only Rs.
                    200/- per annum while enjoying the increasing benefit for
                    the family.
                  </p>
                </div>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading_5">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_5"
                    aria-expanded="true"
                    aria-controls="collapse_5"
                  >
                    5. What’s the procedure to join the scheme?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_5"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_5"
              >
                <div className="panel-body px-3 mb-4">
                  <p>
                    Please get the enrolment form from IMA MS SSS website or
                    physically through your IMA local branch and complete the
                    columns and fill all blank spaces like signatures, photos
                    etc. After filling the form get it counter signed by your
                    branch secretary or President. In case you don’t have IMA
                    Membership certificate, you may provide your membership
                    number and we will verify in our office. In case of
                    difficulty to find this please contact your IMA branch or
                    directly SSS office and we will do that for you. Birth date
                    proof can be any Govt. photo ID except driving license (PAN
                    Card, Aadhar, Passport, etc.) Also visit our website section
                    on application forms and information sheet under member area
                    tab. Detailed table of joining fee is mentioned on the form
                    and you send your payment accordingly.
                    <b>
                      Admission Payment Structure As Per Age W.E.F.1st April
                      2020
                    </b>
                  </p>
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th scope="col">Sr. No.</th>
                        <th scope="col">
                          Amount for Payment As per the Age Groups
                        </th>
                        <th scope="col">Fraternity Distribution Deposit</th>
                        <th scope="col">Admission Fees *</th>
                        <th scope="col">2 X Annual Subscription *</th>
                        <th scope="col">GST on columns *</th>
                        <th scope="col">Total Rs.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Below age 30 years</td>
                        <td>12,000/-</td>
                        <td>1000/-</td>
                        <td>400/-</td>
                        <td>252/-</td>
                        <td>13,652/-</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>30 yr complete to 40 yr</td>
                        <td>12,000/-</td>
                        <td>2000/-</td>
                        <td>400/-</td>
                        <td>432/-</td>
                        <td>14,832/-</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>40 yr complete to 50 yr</td>
                        <td>12,000/-</td>
                        <td>3000/-</td>
                        <td>400/-</td>
                        <td>612/-</td>
                        <td>16,012/-</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>50 yr complete to 55 yr</td>
                        <td>12,000/-</td>
                        <td>10,000/-</td>
                        <td>400/-</td>
                        <td>1872/-</td>
                        <td>24,272/-</td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>55 yr complete to 60 yr *</td>
                        <td>12,000/-</td>
                        <td>20,000/-</td>
                        <td>400/-</td>
                        <td>3672/-</td>
                        <td>36,072/-</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    You can send your duly completed and verified form directly
                    or through your local branch. We have also provided for
                    online enrolment and payment system and we will verify your
                    details online. After verification you can send your
                    payment. Verification at our end will maximally take 2
                    working days. Soon your will be getting your payment receipt
                    online and you can get it printed at your end or save a soft
                    copy of it.
                  </p>
                </div>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading_6">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_6"
                    aria-expanded="true"
                    aria-controls="collapse_6"
                  >
                    6. How long do I need to pay?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_6"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_6"
              >
                <div className="panel-body px-3 mb-4">
                  <p>
                    After initial enrolment the first FFC (Instalment), there is
                    a payment holiday period of 1 year and then it becomes due
                    on subsequent 1 st of April. E.g. If a doctor becomes member
                    in Feb 2020, his first FFC instalment becomes due on
                    01/04/2022 and his 25 th Instalment becomes due on
                    01/04/2046. Henceforth in the initial welcome letter we will
                    mentioned first instalment due and last instalment due.
                  </p>
                </div>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading_7">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_7"
                    aria-expanded="true"
                    aria-controls="collapse_7"
                  >
                    7. Is there any payment required to be done after retirement
                    from the Scheme i.e. after payment of 25 th FFC?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_7"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_7"
              >
                <div className="panel-body px-3 mb-4">
                  <p>
                    YES. We need to pay annual subscription prevalent at that
                    time on a yearly basis. Currently it is Rs. 200/- per annum.
                    This charge is required to meet the administrative expenses,
                    postage, communication etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading_8">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_8"
                    aria-expanded="true"
                    aria-controls="collapse_8"
                  >
                    8. Can I withdraw money if some medical emergency comes?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_8"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_8"
              >
                <div className="panel-body px-3 mb-4">
                  <p>
                    No. For that it is advisable to make alternative arrangement
                    through health insurance policy or investments etc.
                  </p>
                </div>
              </div>
            </div>

            <div className="panel panel-default">
              <div className="panel-heading p-3 mb-3" role="tab" id="heading_9">
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_9"
                    aria-expanded="true"
                    aria-controls="collapse_9"
                  >
                    9. How it is different from term insurance plans?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_9"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_9"
              >
                <div className="panel-body px-3 mb-4">
                  <p>
                    Regular term insurance plans were available only up to 60
                    and later, up to 65 years of age and life cover was not
                    available beyond this age. However from 2015-16 some
                    companies have started giving term insurance life coverage
                    up to 85 and 100years. Our scheme has only 1 restriction
                    before enrolment and i.e. the member must enrol before age
                    of 60. After that he gets life cover irrespective of his
                    attained age. Term insurance requires prior medical exam and
                    with any ailment, there is significant load to the premium.
                    In term insurance premium and the insured amount is fixed,
                    and in SSS both are variable. In SSS, one stops paying after
                    25 instalments and he continues to get coverage till he
                    dies. (Though he has not to contribute fraternity fund i.e.
                    premium). In term insurance one has to pay till 85 or 100
                    years, which becomes increasingly difficult with increasing
                    age. Settlement of death claim is within 1 to 2 weeks in SSS
                    whereas in term insurance, it may take a few months. SSS is
                    only for IMA members and there is feeling of comradeship and
                    this not the case with term insurance.
                  </p>
                </div>
              </div>
            </div>

            <div className="panel panel-default">
              <div
                className="panel-heading p-3 mb-3"
                role="tab"
                id="heading_10"
              >
                <h3 className="panel-title">
                  <a
                    className="collapsed"
                    role="button"
                    title=""
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse_10"
                    aria-expanded="true"
                    aria-controls="collapse_10"
                  >
                    10. I am 36 year old and 4 months pregnant for second time.
                    I had PET in first pregnancy. Am I eligible for this scene?
                  </a>
                </h3>
              </div>
              <div
                id="collapse_10"
                className="panel-collapse collapse"
                role="tabpanel"
                aria-labelledby="heading_10"
              >
                <div className="panel-body px-3 mb-4">
                  <p>YES</p>
                </div>
              </div>
            </div>
            <div
              id="faqshowmore"
              style={{ display: this.state.flag ? 'block' : 'none' }}
            >
              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_11"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_11"
                      aria-expanded="true"
                      aria-controls="collapse_11"
                    >
                      11. Sir I am 56 years old with mild Diabetes .Can I join
                      MS SSS? Which tests are mandatory for joining the scheme?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_11"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_11"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>YES. You can join. No tests are required.</p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_12"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_12"
                      aria-expanded="true"
                      aria-controls="collapse_12"
                    >
                      12. Which is the best age to join the scheme?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_12"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_12"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      Any age, preferably at the earliest opportunity, is the
                      best age to join! This is akin to making a Will. Any Life
                      member of IMA in Maharashtra can join our scheme up to 60
                      years of age and any joining age is ok. But it is
                      advisable to join as early as possible, preferably as soon
                      as you are settled after starting your career in practice
                      or job. This would be around 30 to 35 years of age. The
                      reason to say so is you will not have to pay after
                      completing 25 th annual installment. (Later, you have to
                      pay only annual maintenance fees which are Rs. 200 at
                      present). Person joining at 59 to 60 will have to
                      contribute till his age of 85 to 86 and that becomes
                      somewhat bothersome. So join as early as possible.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_13"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_13"
                      aria-expanded="true"
                      aria-controls="collapse_13"
                    >
                      13. People wish to join just before 60 yrs, is it a better
                      & beneficial way to do it?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_13"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_13"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      As mentioned above, it is preferable to join as early as
                      possible. It is unfortunate that people realize the need
                      to join the scheme after 50s and that too after some
                      medical alert like BP, Diabetes, some chronic ailments
                      etc. It may become difficult to pay in late 80s. It is
                      better to join early.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_14"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_14"
                      aria-expanded="true"
                      aria-controls="collapse_14"
                    >
                      14. What is the economics?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_14"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_14"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      Basic principle is mutual benefit scheme i.e. Colleagues
                      within the scheme contribute Rs. 200 per bereaved family.
                      Out of this Rs. 140 is passed on to the family and Rs. 60
                      is retained by the scheme. This helps to take care of
                      contribution on behalf of retired members after 25 years.
                      Also, the admission fee takes care of adequate corpus and
                      annual fee of Rs. 200 takes care of yearly office and
                      postal expenses of the scheme.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_15"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_15"
                      aria-expanded="true"
                      aria-controls="collapse_15"
                    >
                      15. Is this really a full-fledged & viable scheme?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_15"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_15"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      Yes – We keep our corpus only in FDs of Nationalized banks
                      and other permissible investment avenues as per Charity
                      Commissioners orders. There are no expenses on
                      advertisement, commissions, officers pay etc. except on
                      minimum staff and accounting.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_16"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_16"
                      aria-expanded="true"
                      aria-controls="collapse_16"
                    >
                      16. How is the transparency in accounting and investment
                      details etc?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_16"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_16"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      The schemes accounts and investments are absolutely
                      transparent and they are sent to each member before AGM.
                      And if anyone needs further discloser, they can send a
                      request to the office in writing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_17"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_17"
                      aria-expanded="true"
                      aria-controls="collapse_17"
                    >
                      17. Is SSS mainly beneficial for doctors above 50 years?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_17"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_17"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      The SSS is beneficial at any age! It is not particularly
                      beneficial for a particular age group above 50. One has to
                      remember that the person joining at 50 has to pay till his
                      age of 76 (admission plus next 25 years instalments). It
                      is common experience that with age, it becomes more and
                      more distressing to pay the instalments. Also, defaults
                      are likely thereby resulting in penalties or even
                      discontinuation. Our advice is to enrol at the earliest
                      feasible.
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_18"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_18"
                      aria-expanded="true"
                      aria-controls="collapse_18"
                    >
                      18. I am curious how the scheme sustains?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_18"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_18"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>See the answer to Q. 3 above.</p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_19"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_19"
                      aria-expanded="true"
                      aria-controls="collapse_19"
                    >
                      19. Myself and my wife are IMA Life members. Can we
                      jointly hold a single policy?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_19"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_19"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      NO. The SSS membership is individual and though there is
                      concessional discount for becoming couple member, no such
                      concession is possible for joint couple SSS membership,
                      because these are purely financial matters.
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_20"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_20"
                      aria-expanded="true"
                      aria-controls="collapse_20"
                    >
                      20. How much money I need to pay after 25 years till my
                      death?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_20"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_20"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      Annual maintenance fee need to be paid which is currently
                      Rs. 200/- only. We suggest that you pay in multiples of
                      1000/- which will be adequate for 5, 10, 15 years etc.
                      Please note that for the delayed payment of this nominal
                      annual fee, late fee charges are the same as for regular
                      fraternity contribution. (Rs. 100,200 and 300 depending on
                      length of delay).
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_21"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_21"
                      aria-expanded="true"
                      aria-controls="collapse_21"
                    >
                      21. I am unmarried and my nominee is my mother. Can I
                      change the nomination after marriage?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_21"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_21"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      YES. You can change your nominee any number of times by
                      following a nominal but free procedure.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_22"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_22"
                      aria-expanded="true"
                      aria-controls="collapse_22"
                    >
                      22. Ideally who should be the nominee, parents, spouse or
                      children?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_22"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_22"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      It is up to you—preferably, we suggest, not an NRI (But
                      you can nominate an NRI also).
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_23"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_23"
                      aria-expanded="true"
                      aria-controls="collapse_23"
                    >
                      23. What will be the benefits of becoming SSS member?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_23"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_23"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      Please see the answer to Q. 6 above. As an example, a
                      doctor who became SSS member in 1998 and unfortunately
                      died in 2016 had contributed Rs. 35000/- by way of
                      admission fee and fraternity contribution for 14
                      instalments --- the family received Rs. 9,16,000/- after
                      his untimely death within 10 days.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_24"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_24"
                      aria-expanded="true"
                      aria-controls="collapse_24"
                    >
                      24. If there any lock in period to receive claim amount by
                      family?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_24"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_24"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      A new member is not entitled for any claim for 365 days
                      after joining as there is no medical examination. But this
                      does not apply to accidental death. The death claim amount
                      is payable to the family irrespective of age at death or
                      age of joining. We release the payment within 2 weeks
                      after duly filled up advance receipt and death pass and
                      death intimation are received by the office.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_25"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_25"
                      aria-expanded="true"
                      aria-controls="collapse_25"
                    >
                      25. When does one become defaulter?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_25"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_25"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      Yearly FFC falls due on 1 st April each year and is
                      required to be paid latest by 31 st May. If not paid by
                      this date, from 1 st June to 31 st July one has to pay FFC
                      + Rs. 100/- as late fee. If not paid by 31 st July, from 1
                      st August to 30 th September, FFC + Rs. 300/- as late fee
                      need to be paid. From 1 st October, if FFC remains unpaid,
                      the member is declared defaulter and his membership
                      remains terminated till it is revived as per the
                      procedure.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_26"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_26"
                      aria-expanded="true"
                      aria-controls="collapse_26"
                    >
                      26. How revival of defaulted member is done?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_26"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_26"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      If the member has become defaulter as seen above, his
                      membership can be revived for up to maximum of 3 years
                      including the year of defaulter as follows:If the current
                      years default is to be revived – he will be asked to pay
                      admission charges as per the current age + defaulting
                      years FFC + 300/-. If the default is for 2 year’s, he will
                      have to pay admission fee as per current age + 2 years FFC
                      + Rs. 600/- (2 year’s late fee). If the default is for 3
                      years, he will have to pay admission fee as per current
                      age + 2 years FFC + Rs. 900/- (3 year’s late fee).
                      Additionally if there is increase in Advance Fraternity
                      Contribution from the deposited amount the difference has
                      to be topped up. A new application form with Photo,
                      Nominee details, etc. needs to be submitted, though the
                      seniority is maintained as before (This is the greatest
                      advantage and his membership no. remains the same). i.e.
                      his last date of FFC contribution remains the same.
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_27"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_27"
                      aria-expanded="true"
                      aria-controls="collapse_27"
                    >
                      27. Are there any conditions when the benefit is not given
                      to family?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_27"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_27"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>NO. The claim is always settled.</p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_28"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_28"
                      aria-expanded="true"
                      aria-controls="collapse_28"
                    >
                      28. If there is lapse in the membership can it be revived?
                      Procedure for revival?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_28"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_28"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>Please see the answer to Q. 22.</p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_29"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_29"
                      aria-expanded="true"
                      aria-controls="collapse_29"
                    >
                      29. What are options for paying the fraternity
                      contributions?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_29"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_29"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      Fraternity contribution can be paid by cash in head office
                      only – the scheme will not be responsible for cash payment
                      done anywhere else or to unauthorized person. Please take
                      receipt on the spot. You can pay better by cheque (Payable
                      at par at Pune) or DD payable at Pune. Soon we will have
                      the facility to pay online.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_30"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_30"
                      aria-expanded="true"
                      aria-controls="collapse_30"
                    >
                      30. What happens if member is defaulter and dies before
                      clearing the default?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_30"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_30"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      First, it is necessary that the member does not default.
                      His family will lose all the money if he does not clear
                      his default before death.Please avoid this unfortunate
                      situation by timely payments or may be paying advance
                      voluntary deposit.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_31"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_31"
                      aria-expanded="true"
                      aria-controls="collapse_31"
                    >
                      31. I am unmarried and my nominee is my mother / father /
                      sister etc. Can I change my nominee after my marriage?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_31"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_31"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      Yes. You can change your nominee by filling one nomination
                      form (available on website / or get it from us). And
                      promptly sending it to the office. As soon as we get duly
                      completed new nomination form we will complete the change
                      of nominee procedure and inform you by email. It is
                      advisable to have younger nominee than yourself. We
                      suggest that you asked for duplicate membership
                      certificate with the new nominees name from the office by
                      making nominal payment of Rs. 100/-.
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_32"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_32"
                      aria-expanded="true"
                      aria-controls="collapse_32"
                    >
                      32. Myself and my wife are couple (Joint) IMA Life
                      members. Can we jointly hold a single policy?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_32"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_32"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      NO. You will be issued 2 separate Social Security
                      Certificates and rightly so.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_33"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_33"
                      aria-expanded="true"
                      aria-controls="collapse_33"
                    >
                      33. I am going abroad for 3 years -- Can we pay advance?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_33"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_33"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      YES. There is a system of voluntary advance deposit (VAD)
                      by which you can pay 5000 or multiples of 5000, any
                      amount, by any of the methods mentioned above and we will
                      adjust your yearly FFC towards it and at the time of
                      yearly notice you will be send data of remaining amount
                      with the scheme. This will avoid from late fees or from
                      getting into defaulters list.
                    </p>
                  </div>
                </div>
              </div>

              <div className="panel panel-default">
                <div
                  className="panel-heading p-3 mb-3"
                  role="tab"
                  id="heading_34"
                >
                  <h3 className="panel-title">
                    <a
                      className="collapsed"
                      role="button"
                      title=""
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapse_34"
                      aria-expanded="true"
                      aria-controls="collapse_34"
                    >
                      34. What happens if the nominee (s) dies at the same time
                      as the member? or before nomination process is incomplete
                      at the time of change of nomination or death of the
                      nominee?
                    </a>
                  </h3>
                </div>
                <div
                  id="collapse_34"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="heading_34"
                >
                  <div className="panel-body px-3 mb-4">
                    <p>
                      If the member and nominee both die almost simultaneously
                      which is likely in accident cases and additional nominee
                      is not mentioned, then the beneficial amount will be paid
                      in accordance to the Will of the member. However if such
                      Will is not done by the member and no nominee is
                      available, a letter of administration or decree of
                      succession from competent authority or court will the
                      required.Please note that the beneficial amount will be
                      the same as per calculation at the date of his death, and
                      NOT at the time of getting the order or decree. Till such
                      time, the money will remain with IMA MS SSS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h4
              onClick={this.showForm}
              style={{ color: 'blue', cursor: 'pointer' }}
            >
              Click here to ask your question
            </h4>
            <div style={{ display: this.state.formFlag ? 'block' : 'none' }}>
              <AskQue />
            </div>
            <div>
              <button
                className="btn_landing"
                style={{ float: 'right' }}
                onClick={this.showMoreButton}
                id="showMoreBtn"
              >
                {this.state.showName}
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default FAQ;
