import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from 'reactstrap';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
const ls = require('local-storage');

const PaybarModalAnnual = props => {
  const [paymentData, setPaymentData] = useState([]);
  const [totalMoney, setTotalMoney] = useState(0); //eslint-disable-line
  const [paymentTableModal, setPaymentTableModal] = useState(false);
  const [amountModal, setAmountModal] = useState(false);
  const [userDeposit, setUserDeposit] = useState(0);

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://pgi.billdesk.com/payments-checkout-widget/src/app.bundle.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []); //eslint-disable-line

  useEffect(() => {
    setPaymentData(props.paymentData);
    setTotalMoney(props.amount);
  }, [props.paymentData, props.amount]);

  const makePayment = async e => {
    e.preventDefault();

    try {
      const payment_amount = totalMoney + Math.abs(userDeposit);
      const res = await axios.post(
        '/api/payment/checksum',
        {
          name: props.data.name,
          amount: payment_amount,
          voluntary_advanced_deposit: props.data.voluntary_advanced_deposit,
          new_advanced_deposit: userDeposit,
          dues: props.data.dues,
          email: props.data.email,
          phone: props.data.phone,
          sss_no: props.data.sss_no,
          age: props.data.age
        },
        { headers: { 'ima-auth-token': ls.get('ima-auth-token') } },
      );

      window.bdPayment.initialize({
        msg: res.data.msg,
        options: {
          enableChildWindowPosting: true,
          enablePaymentRetry: true,
          retry_attempt_count: 2,
        },
        callbackUrl: 'https://imamssss.com/api/payment/getPayAnnualMsg',
      });
    } catch (err) {
      console.log(err);
    }
    setAmountModal(false);
    setPaymentTableModal(false);
  };

  const fetchReceipt = async e => {
    e.preventDefault();

    try {
      const response = await axios.get(
        `/api/users/fetch-receipt-url/${props.data.sss_no}`,
        { headers: { 'ima-auth-token': ls.get('ima-auth-token') } },
      );
      const data = response.data;
      window.open(data.url, '_blank');
    } catch (error) {
      props.notification.addNotification({
        title: 'Error',
        message: error.response?.data.error,
        level: 'error',
      });
    }
  };

  return (
    <div>
      <Button
        className="btn btn-block active text-center"
        color="secondary"
        active
        onClick={() => setPaymentTableModal(true)}
        // disabled
      >
        Pay FFC
      </Button>

      {props.data?.transaction_status === 'SUCCESS' && (
        <Button
          className="btn btn-block active text-center"
          color="primary"
          active
          onClick={fetchReceipt}
        >
          Download Receipt
        </Button>
      )}

      <Modal
        isOpen={paymentTableModal}
        toggle={() => setPaymentTableModal(!paymentTableModal)}
        className={props.className}
      >
        <ModalHeader toggle={() => setPaymentTableModal(!paymentTableModal)}>
          Fees Breakdown
        </ModalHeader>
        <ModalBody>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Amount (in Rs.)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.type}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {'\u20B9'} {row.amount}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell component="th" scope="row">
                  Total Fees (with GST)
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>
                    {'\u20B9'} {totalMoney}
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Additional User Voluntary Deposit
                </TableCell>
                <TableCell component="th" scope="row">
                  <Input
                    style={{ paddingLeft: 5 }}
                    value={userDeposit}
                    className={`without-padding`}
                    onChange={e => {
                      e.persist();
                      setUserDeposit(
                        parseInt(e.currentTarget.value)
                          ? parseInt(e.currentTarget.value)
                          : 0,
                      );
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Grand Total (With User Voluntary Deposit)
                </TableCell>
                <TableCell component="th" scope="row">
                  <b>
                    {'\u20B9'} {totalMoney + userDeposit}
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setPaymentTableModal(false);
              setAmountModal(true);
            }}
          >
            Next
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => setPaymentTableModal(!paymentTableModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={amountModal}
        toggle={() => setAmountModal(!amountModal)}
        className={props.className}
      >
        <ModalHeader toggle={() => setAmountModal(!amountModal)}>
          Fees Calculated
        </ModalHeader>
        <ModalBody>
          Fees to be paid (in Rs.)
          <Input disabled={true} value={totalMoney + userDeposit}></Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={makePayment}>
            Pay
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => setAmountModal(!amountModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PaybarModalAnnual;
