import React from 'react';
import AboutTabs from './about_tabs';
import SSS from './sss';

class About extends React.Component {
  render() {
    return (
      <section
        className="wpb_row row-fluid section-padd row-has-fill row-o-equal-height row-o-content-middle row-flex bg-light"
        id="IMA_Home"
      >
        <div className="container">
          <AboutTabs />
          <SSS />
        </div>
      </section>
    );
  }
}

export default About;
