import React from 'react';

class AboutUs extends React.Component {
  render() {
    return (
      <section
        className="wpb_row row-fluid section-padd row-has-fill row-o-equal-height row-o-content-middle row-flex bg-light"
        id="AboutUs"
      >
        <div className="container">
          <h2>ABOUT US</h2>
          <p>
            Indian Medical Association (IMA) is the largest NGOs in India having
            all members belonging to allopathic (modern medicine) stream of
            doctors in India. It has its headquarters in New Delhi with 32 State
            branches. The total membership is 4.25 lacs all across India. Indian
            Medical Association (IMA) Maharashtra State branch (MS) is the
            largest and vibrant State branch in India having over 45,000 doctors
            from Maharashtra State.[IMA MS] .IMA MS has 216 branches at city,
            taulka and village levels.
          </p>
          <p>
            One of the main Aims of IMA is to provide continued medical
            education and try and solve problems of doctor membeers in practice
            as well as in service. Indian Medical Association has welfare of the
            members in its list of objects of association and accordingly, has
            started "Social Security Scheme" labelled as IMA MS SSS; which is a
            mutually beneficial scheme for members. IMA MS SSS has its own
            constitutions, though SSS operates under the general directives of
            IMA MS. IMA MS SSS office is situated at Office no. 408, Dr Nitu
            Mandke IMA House, 992 Shukrawar Peth, Tilak Road, Pune 411002 and
            contact no 020-24464771 & -24430042.
          </p>
          <p>
            IMA MS SSS has currently 8,659 members and 6718 active members (i.e.
            after deducting retired and deceased members as well as those who
            had resigned or defaulted and hence deleted. The family members
            (nominee) of the deceased member at present is given claim of Rs.
            9,24,560/- The claim settlement ratio is over 99% and that too
            within 7 days of completion of paperwork, which is very nominal. It
            is very simple and member friendly. As the cost of running the
            Scheme is only on basic infrastructure and minimum staffing, and no
            expenses on commissions, advertising and profiteering, as opposed to
            any insurance company, the cost :benefit ratio is high and hence, it
            has become the main attraction for becoming even IMA member. Also,
            after 25 years’ of contribution 1 year after joining the Scheme
            while reaping the increasing beneficial amount and that too tax
            effective in the hands of nominee, down the years, is not matched by
            any other insurance system, leave aside the new term insurances.
          </p>
          {/* <p>For enrolling yourself, visit member’s area here -></p> */}
          <p>Office Bearers are</p>
          <ul>
            <li>Dr. Rajgopal Kalani, Chairman, Pune</li>
            <li>Dr. Prakash Marathe, Secretary</li>
            <li>Dr. Suhas Nene, Treasurer</li>
            <li>Dr. Ambrish Shahade, Joint Secretary</li>
          </ul>
        </div>
      </section>
    );
  }
}
export default AboutUs;
