import React from "react";
import Axios from "axios";
import { withRouter } from 'react-router-dom';

function Announcement() {
    return (
        <div dir="ltr" style={{margin: 100}}>
            <div
                className="gmail_default"
                style={{ fontFamily: "verdana,sans-serif", fontSize: "small" }}
            >
                <span
                    style={{
                        fontFamily: "georgia,serif",
                        fontSize: "large",
                        textAlign: "justify"
                    }}
                >
                    Dear IMA MS SSS Members,
                </span>
                <br />
            </div>
            <div className="gmail_quote">
                <div dir="ltr">
                    <div>
                        <p style={{ textAlign: "justify" }}>
                            <span lang="EN-US">
                                <font face="georgia, serif" size={4}>
                                    &nbsp;
                                </font>
                            </span>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span lang="EN-US">
                                <font face="georgia, serif" size={4}>
                                    Happy New Year and Best Wishes for Makar Sankranti!
                                </font>
                            </span>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span lang="EN-US">
                                <font face="georgia, serif" size={4}>
                                    <br />
                                </font>
                            </span>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span lang="EN-US">
                                <font face="georgia, serif" size={4}>
                                    IMA MS SSS is also metamorphosing from today, 15.01.2024 after a
                                    wait of major changes done 6 years ago i.e. in 2017.&nbsp; After a
                                    long process of deliberations, circulation and acceptance by
                                    Office Bearers, Managing Committee, AGM on 19.11.2023 and finally
                                    by Extraordinary AGM on 14.01.2024 several significant changes
                                    have come into force to make the scheme more robust and therefore
                                    we are sending this special communication to all of you.
                                </font>
                            </span>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span lang="EN-US">
                                <font face="georgia, serif" size={4}>
                                    We are enclosing the total changes that will be implemented with
                                    immediate effect in the PDF format and a simplified version of the
                                    same for better understanding and comprehension.
                                </font>
                            </span>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <span lang="EN-US">
                                <font face="georgia, serif" size={4}>
                                    In a nutshell the salient features of the scheme are as follows:
                                </font>
                            </span>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <b>
                                    <span lang="EN-US">
                                        1<span>)</span>
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    &nbsp;No changes for retired members i.e. up to Social Security
                                    no. 1 to 1256. Except that the beneficial amount is fixed at Rs.
                                    10 lakhs (currently it’s around Rs. 9.40 lakhs and variable). Exit
                                    option is restrictive and limited to Rs. 5 lakhs
                                    <b>…refer to slide no. 8.</b>
                                </span>
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <b>
                                    <span lang="EN-US">
                                        2<span>)&nbsp;</span>
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    There will be fixed FFC for new entrants at any age (much less for
                                    younger age group and very reasonable for elders also); as well as
                                    those existing members below 45 years of age
                                    <b>…refer to slide no. 3.</b>
                                </span>
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <b>
                                    <span lang="EN-US">
                                        3<span>)</span>&nbsp;
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    A new member can opt for multiple units of beneficial amount e.g.
                                    1 unit of 10 lakhs is basic; but member can opt for such 1.5, 2,
                                    2.5 or 3 units etc. please call in person for further details
                                    <b>…refer to slide no. 3.</b>
                                </span>
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <b>
                                    <span lang="EN-US">
                                        4<span>)</span>
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    &nbsp;Those existing and contributing members beyond the age of
                                    &gt; 45 years have an option to continue as per the old scheme.
                                    However the benefit amount is fixed at Rs. 10 lakhs.
                                </span>
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <span>
                                    <b>
                                        <span lang="EN-US">4</span>
                                    </b>
                                </span>
                                <b>
                                    <span lang="EN-US">
                                        A<span>)</span>
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    &nbsp;Those who do not shift to New Scheme by specifically signing
                                    the denial option form will continue to pay variable FFC but Death
                                    Benefit is fixed at Rs. 10 lakh
                                    <span />s
                                    <span>
                                        . FFC will be calculated as per old scheme i.e. = (No. of deaths
                                        in the total scheme in that year X Rs. 200/-
                                    </span>
                                </span>
                                <span>
                                    <span lang="EN-US">&nbsp;</span>
                                </span>
                                <span>
                                    <span lang="EN-US">+ Annual Subscription of Rs. 250/-</span>
                                </span>
                                <span>
                                    <span lang="EN-US">&nbsp;</span>
                                </span>
                                <span>
                                    <span lang="EN-US">
                                        + No. of exits X Rs. 120/- + Applicable GST). They&nbsp;
                                    </span>
                                </span>
                                <span lang="EN-US">
                                    will be able to get exit option of Rs. 6 lakhs only
                                    <span>
                                        {" "}
                                        and No. of exits from this group in each year will be decided by
                                        the Management Committee as per the rules
                                    </span>
                                    <b>…refer to slide no. 8.</b>
                                </span>
                                <span>
                                    <b>
                                        <span lang="EN-US" />
                                    </b>
                                </span>
                                <span>
                                    <b>
                                        <span lang="EN-US">&nbsp;</span>
                                    </b>
                                </span>
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <span>
                                    <b>
                                        <span lang="EN-US">4</span>
                                    </b>
                                </span>
                                <b>
                                    <span lang="EN-US">
                                        B<span>)</span>
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    &nbsp;If they opt for shifting to a new scheme by signing the
                                    Consent form for the same (form attached) OR remaining silent and
                                    unresponsive, they will be considered to have consented by
                                    default, they will get the benefit of fixed FFC for the whole
                                    remaining tenure of payment of FFCs at Rs. 11,800/- + GST.&nbsp;
                                    &nbsp;&nbsp;
                                </span>
                                Beneficial amount is fixed at Rs. 10 lakhs and also get&nbsp;higher
                                Exit option of Rs. 10 lakhs<b>…refer to slide no. 9.</b>&nbsp;
                                &nbsp; &nbsp;
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <b>
                                    <span lang="EN-US">
                                        5<span>)</span>&nbsp;
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    Exit scheme of Rs. 10 lakhs is introduced for all new scheme
                                    members. Details enclosed in the PDF<b>…refer to slide no. 9.</b>
                                </span>
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <b>
                                    <span lang="EN-US">
                                        6<span>)</span>&nbsp;
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    Facility for paying additional installments with discounts
                                    <b>…refer to slide no. 7.</b>
                                </span>
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <b>
                                    <span lang="EN-US">
                                        7<span>)</span>&nbsp;
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    Facility to pay deposit in 1 or 3 installments to obviate need to
                                    bother about additional GST payment each year for the remaining
                                    tenure<b>…refer to slide no. 6.</b>&nbsp; This being a deposit, it
                                    will be finally returned.
                                </span>
                            </font>
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            <font face="georgia, serif" size={4}>
                                <b>
                                    <span lang="EN-US">
                                        8<span>)</span>&nbsp;
                                    </span>
                                </b>
                                <span lang="EN-US">
                                    Another change coming into force from 01.04.2024 is the Annual
                                    Subscription fee of Rs. 250/- instead of Rs. 200/- per annum
                                    <b>…refer to slide no. 1.</b>
                                </span>
                            </font>
                        </p>
                        <font face="georgia, serif" size={4}>
                            <span lang="EN-US" style={{ lineHeight: "107%" }}>
                                Though all these changes seem complicated and confusing, be rest
                                assured that they have been carefully and thoughtfully worked out
                                keeping individual member’s interest as the top priority. If you
                                have any queries please refer to attached PPT and after keenly going
                                through the same, if you still have some doubts please call either
                                Secretary Dr. Prakash Marathe (9823071292) or Dr. Ambrish Shahade
                                (9422616464) or Dr. Suhas Nene (9822115335).
                            </span>
                            <br />
                        </font>
                    </div>
                    <div className="gmail_quote">
                        <div dir="ltr">
                            <div className="gmail_quote">
                                <div dir="ltr">
                                    <div>
                                        <div>
                                            <p style={{ textAlign: "justify" }}>
                                                <span lang="EN-US">
                                                    <font face="georgia, serif" size={4}></font>
                                                </span>
                                            </p>
                                            <p style={{ textAlign: "justify" }}>
                                                <b>
                                                    <span lang="EN-US">
                                                        <font face="georgia, serif" size={4}>
                                                            Enclosed:-
                                                        </font>
                                                    </span>
                                                </b>
                                            </p>
                                            <p
                                                style={{
                                                    textAlign: "justify",
                                                    backgroundImage: "initial",
                                                    backgroundPosition: "initial",
                                                    backgroundSize: "initial",
                                                    backgroundRepeat: "initial",
                                                    backgroundOrigin: "initial",
                                                    backgroundClip: "initial"
                                                }}
                                            >
                                                <font face="georgia, serif" size={4}>
                                                    <span lang="EN-US">1.</span>
                                                    <span lang="EN-US">&nbsp; </span>
                                                    <span lang="EN-US">
                                                        Consent Form and denial form 14.01.2024
                                                    </span>
                                                </font>
                                            </p>
                                            <p
                                                style={{
                                                    textAlign: "justify",
                                                    backgroundImage: "initial",
                                                    backgroundPosition: "initial",
                                                    backgroundSize: "initial",
                                                    backgroundRepeat: "initial",
                                                    backgroundOrigin: "initial",
                                                    backgroundClip: "initial"
                                                }}
                                            >
                                                <font face="georgia, serif" size={4}>
                                                    <span lang="EN-US">2.</span>
                                                    <span lang="EN-US">&nbsp;&nbsp;</span>
                                                    <span lang="EN-US">
                                                        PPT Resolutions of all changes 14.01.2024
                                                    </span>
                                                </font>
                                            </p>
                                        </div>
                                        <div className="gmail_quote">
                                            <div dir="ltr">
                                                <div className="gmail_quote">
                                                    <div dir="ltr">
                                                        <div>
                                                            <p
                                                                style={{
                                                                    margin: "0cm 0cm 0.0001pt 36pt",
                                                                    textAlign: "justify"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        <br />
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        --
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        Thanking You,
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        &nbsp;
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        Yours Sincerely,
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        &nbsp;
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        Dr. Dinesh Thakare / Dr. Saurabh Sanjanwala
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        President, IMA MS / Hon. Secretary, IMA MS
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        &nbsp;
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        Dr. Rajgopal Kalani / Dr. Prakash Marathe
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        Chairman / Hon. Secretary
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        9422176227 / 9823071292
                                                                    </font>
                                                                </span>
                                                            </p>
                                                            <p
                                                                style={{
                                                                    margin: "0cm 0cm 0.0001pt 36pt",
                                                                    textAlign: "justify"
                                                                }}
                                                            ></p>
                                                            <p
                                                                style={{
                                                                    textAlign: "justify",
                                                                    margin: "0cm 0cm 0.0001pt"
                                                                }}
                                                            >
                                                                <span lang="EN-US">
                                                                    <font face="georgia, serif" size={4}>
                                                                        IMA Maharashtra State SSS
                                                                    </font>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        dir="ltr"
                                        className="gmail_signature"
                                        data-smartmail="gmail_signature"
                                    >
                                        <div dir="ltr">
                                            <div>
                                                <font face="georgia, serif" size={4}>
                                                    <br style={{ color: "rgb(51,51,51)" }} />
                                                    <br style={{ color: "rgb(51,51,51)" }} />
                                                    <span style={{ color: "rgb(51,51,51)" }}>
                                                        IMA Maharashtra State Social Security Scheme
                                                    </span>
                                                    <br style={{ color: "rgb(51,51,51)" }} />
                                                    <span style={{ color: "rgb(51,51,51)" }}>
                                                        Office No. 301, Dr Nitu Mandke IMA House
                                                    </span>
                                                    <br style={{ color: "rgb(51,51,51)" }} />
                                                    <span style={{ color: "rgb(51,51,51)" }}>
                                                        992 Shukrawar Peth
                                                    </span>
                                                    <br style={{ color: "rgb(51,51,51)" }} />
                                                    <span style={{ color: "rgb(51,51,51)" }}>
                                                        Tilak Road, Swargate,
                                                    </span>
                                                    <br style={{ color: "rgb(51,51,51)" }} />
                                                    <span style={{ color: "rgb(51,51,51)" }}>
                                                        Pune 411002
                                                    </span>
                                                    <br style={{ color: "rgb(51,51,51)" }} />
                                                    <span style={{ color: "rgb(51,51,51)" }}>
                                                        (Tel 020-24464771; -24430042; M: 09699652255)
                                                    </span>
                                                    <br style={{ color: "rgb(51,51,51)" }} />
                                                    <span style={{ color: "rgb(51,51,51)" }}>
                                                        Email:{" "}
                                                        <a href="mailto:imamsssspune@gmail.com" target="_blank">
                                                            imamsssspune@gmail.com
                                                        </a>
                                                    </span>
                                                </font>
                                                <div>
                                                    <font color="#333333" face="georgia, serif" size={4}>
                                                        Website:{" "}
                                                        <a href="http://www.imamssss.com" target="_blank">
                                                            www.imamssss.com
                                                        </a>
                                                        <br />
                                                    </font>
                                                    <div>
                                                        <span style={{ color: "rgb(51,51,51)" }}>
                                                            <font face="georgia, serif" size={4}>
                                                                <img
                                                                    width={96}
                                                                    height={91}
                                                                    src="https://imamsssspune.s3.ap-south-1.amazonaws.com/logo.jpeg"
                                                                />
                                                                <br />
                                                            </font>
                                                        </span>
                                                        <div style={{marginTop: 20}}>
                                                            <p>
                                                            <font color="#333333" face="georgia, serif" size={5} bold> 
                                                            Attachements:
                                                            </font>
                                                            <font color="#333333" face="georgia, serif" size={4}> 
                                                            <ol>
                                                                <li><a href="https://imamsssspune.s3.ap-south-1.amazonaws.com/New+Scheme+Details+to+IMA+MS+SSS+Members_1.jpg" target="_blank">
                                                                New Scheme Details to IMA MS SSS Members_1
                                                                    </a></li>
                                                                <li>
                                                                    <a href="https://imamsssspune.s3.ap-south-1.amazonaws.com/New+Scheme+Details+to+IMA+MS+SSS+Members_2.jpg" target="_blank">
                                                                    New Scheme Details to IMA MS SSS Members_2
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="https://imamsssspune.s3.ap-south-1.amazonaws.com/Consent+Form+and+denial+form+14.01.2024.pdf" target="_blank">
                                                                    Consent Form and denial form 14.01.2024
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="https://imamsssspune.s3.ap-south-1.amazonaws.com/New+Scheme+Resolution+passed+in+AGM+14.01.2024.pdf" target="_blank">
                                                                    New Scheme Resolution passed in AGM 14.01.2024
                                                                    </a>
                                                                </li>
                                                            </ol>
                                                            </font>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default withRouter(Announcement);