import React from 'react';
import Form from './form';
import BelowContact from './below_form';

class Contact extends React.Component {
  render() {
    return (
      <div className="entry-content">
        <div className="container">
          <div className="boxed-content">
            {/* <AboveContact /> */}
            {/* <section className="wpb_row row-fluid section-padd no-bot">
              <div className="container">
                <div className="row">
                  <div className="wpb_column column_container col-sm-12">
                    <div className="column-inner">
                      <div className="wpb_wrapper">
                        <div className="gray-line"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            <Form />
            <BelowContact />
          </div>
        </div>
      </div>
    );
  }
}
export default Contact;
