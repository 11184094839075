import React, { useState, useEffect, useRef } from 'react';
import Page from '../../components/Page';
import OurTable from '../../admin/table';
import { Row, Col, Spinner } from 'reactstrap';
import axios from 'axios';

const columns = [
  {
    name: 'sss_no',
    label: 'SSS Number',
    sort: true,
  },
  {
    name: 'name',
    label: 'Name',
  },
];

const Members = () => {
  const x = useRef();
  const [data, setData] = useState([{}]);
  const [fetchDone, setFetchDone] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios
        .get('/api/users/listSSSNoAndName', {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .catch(err => {
          alert('Error while fetching User List, please try again later!');
        });
      if (result) {
        let data = result.data.users;
        setData(data);
        x.current = data;
        setFetchDone(true);
      } else {
        setFetchDone(true);
      }
    };
    fetchData();
  }, []);
  // eslint-disable-next-line
  const [options, setOptions] = useState([
    {
      filter: false,
      searchOpen: true,
      // filterType: 'dropdown',
      searchPlaceholder: 'Find your SSS number here.',
      selectableRows: false,
      responsive: 'scroll',
      rowsPerPage: 100,
      download: false,
      print: false,
      viewColumns: false,
      sortFilterList: true,
      // downloadOptions: {
      //   filterOptions: {
      //     useDisplayedColumnsOnly: true,
      //     useDisplayedRowsOnly: true,
      //   },
      // },
    },
  ]);
  return (
    <Page
    // title="Members"
    // breadcrumbs={[{ name: 'Users', active: true }]}
    // className="TablePage"
    >
      <br />
      <br />
      {/* containerizing member table add this to <Row>  --> className="container mx-auto" */}
      <Row>
        <Col sm="12">
          {fetchDone ? (
            <OurTable
              title="Members List"
              columns={columns}
              data={data}
              options={options}
            />
          ) : (
            <Spinner
              style={{ marginLeft: '45vw', marginTop: '20vh' }}
              color="primary"
            />
          )}
        </Col>
      </Row>
    </Page>
  );
};

export default Members;
