import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
const ls = require('local-storage');
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [credentialsValid, setCredentialsValid] = useState(false);
  const [validated, setValidated] = useState(false);
  var token = ls.get('ima-admin-auth-token');
  useEffect(() => {
    const verifyToken = async () => {
      token = ls.get('ima-admin-auth-token');
      await axios
        .post('/api/admins/verify', {
          headers: { 'ima-admin-auth-token': token },
        })
        .then(resp => {
          setCredentialsValid(resp.data.res);
          setValidated(true);
        })
        .catch(err => {
          setCredentialsValid(false);
          setValidated(true);
        });
    };
    verifyToken();
  });

  if (credentialsValid && validated) {
    return (
      <Route {...rest} render={props => <Component {...rest} {...props} />} />
    );
  } else if (!credentialsValid && validated) {
    return <Redirect to="/adminsignin" />;
  } else {
    return <h1>Loading...</h1>;
  }
};
export default ProtectedRoute;
