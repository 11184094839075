import React from 'react';
import LazyLoad from 'react-lazyload';
import './style.css';
import { Carousel } from 'react-responsive-carousel';
// import im1 from './bannerImages/1_mobile.jpg';
// import im2 from './bannerImages/2.jpg';
// import im3 from './bannerImages/3.jpg';
const BannerSlider = props => {
  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      autoPlay={true}
      infiniteLoop={true}
    >
      <div>
        <LazyLoad
          height="100%"
          placeholder={
            <img src="https://via.placeholder.com/1920x700" alt="" />
          }
          once={true}
        >
          <picture>
            <source
              media="(max-device-width: 480px)"
              srcSet={require('./bannerImages/1_mobile.jpg')}
            />
            <img src={require('./bannerImages/1.jpg')} alt="yah" />
          </picture>
        </LazyLoad>
        <p className="legend">
          Hasselfree, medical free, cheapest family benefit with fastest
          settlement of claim!
        </p>
      </div>
      <div>
        <LazyLoad
          height="100%"
          placeholder={
            <img src="https://via.placeholder.com/1920x700" alt="" />
          }
          once={true}
        >
          <picture>
            <source
              media="(max-device-width: 480px)"
              srcSet={require('./bannerImages/2_mobile.jpg')}
            />
            <img src={require('./bannerImages/2.jpg')} alt="yah" />
          </picture>
        </LazyLoad>
        <p className="legend">
          No age is too young for calamities--secure your family today through
          your SSS!
        </p>
      </div>
      <div>
        <LazyLoad
          height="100%"
          placeholder={
            <img src="https://via.placeholder.com/1920x700" alt="" />
          }
          once={true}
        >
          <picture>
            <source
              media="(max-device-width: 480px)"
              srcSet={require('./bannerImages/3_mobile.jpg')}
            />
            <img src={require('./bannerImages/3.jpg')} alt="yah" />
          </picture>
        </LazyLoad>
        <p className="legend">
          Join today to reap increasing benefits even after retiring!
        </p>
      </div>
    </Carousel>
  );
};

export default BannerSlider;
