import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
const ls = require('local-storage');
const ProtectedRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const [credentialsValid, setCredentialsValid] = useState(false);
  const [validated, setValidated] = useState(false);
  var token = ls.get('ima-auth-token');
  useEffect(() => {
    const verifyToken = async () => {
      token = ls.get('ima-auth-token');
      await axios
        .post('/api/users/verify', { /* Backend route to be written. */
          headers: { 'ima-auth-token': token },
        })
        .then(resp => {
          setCredentialsValid(resp.data.res);
          setValidated(true);
        })
        .catch(err => {
          setCredentialsValid(false);
          setValidated(true);
        });
    };
    verifyToken();
  });

  if (credentialsValid && validated) {
    return (
      <Route {...rest} render={props => (
        <Layout>
          <Component {...rest} {...props} />
        </Layout>
        )
      } />
    );
  } else if (!credentialsValid && validated) {
    return <Redirect to="/landing" />;
  } else {
    return <h1>Loading...</h1>;
  }
};
export default ProtectedRoute;