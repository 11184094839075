import React from 'react';
import componentQueries from 'react-component-queries';
import 'styles/reduction.scss';
import { Col, Spinner } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import HTable from './HTable';
import PayBar from './PayBar';
import axios from 'axios';

class UserDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      loading: this.props.loading,
      redirect: false,
      paymentData: [],
      totalMoney: 0,
    };
  }

  componentDidMount() {
    axios.get('/api/users/existingUserRegistrationCost').then(res => {
      let payments = [];
      let total = 0;
      const oldMembers = [1281, 1308, 1374, 1416, 1419, 1431, 1532, 1617, 1956, 2169, 2248, 2349, 2351, 2360, 2406, 2491, 2587, 2588, 2916, 3031, 3126, 3543, 3544, 4181, 4182, 4183, 4245, 4721, 4837, 4886, 5100, 5935, 5992, 6062, 6669, 7391, 8223];

      if(oldMembers.includes(this.props.data.sss_no)){
        let value = res.data['Existing_User_FFC_2024_old_scheme']
        total = value;
        payments.push({ type: 'Existing User FFC 2024 old scheme', amount: value });
      }
      else{
        const age = this.props.data.age;
        if(age <= 30){
          let value = res.data['Existing_User_FFC_2024_under_30']
          total = value;
          payments.push({ type: 'Existing User FFC 2024 under 30', amount: value });
        }
        else if(age > 30 && age <= 34){
          let value = res.data['Existing_User_FFC_2024_30_34']
          total = value;
          payments.push({ type: 'Existing User FFC 2024 30-34', amount: value });
        }
        else if(age > 34 && age <= 37){
          let value = res.data['Existing_User_FFC_2024_34_37']
          total = value;
          payments.push({ type: 'Existing User FFC 2024 34-37', amount: value });
        }
        else if(age > 37 && age <= 40){
          let value = res.data['Existing_User_FFC_2024_37_40']
          total = value;
          payments.push({ type: 'Existing User FFC 2024 37-40', amount: value });
        }
        else if(age > 40 && age <= 45){
          let value = res.data['Existing_User_FFC_2024_40_45']
          total = value;
          payments.push({ type: 'Existing User FFC 2024 40-45', amount: value });
        }
        else{
          let value = res.data['Existing_User_FFC_2024_above_45']
          total = value;
          payments.push({ type: 'Existing User FFC 2024 above 45', amount: value });
        }
      }

      let totalPayment = total;
      payments.push({
        type: 'Voluntary Advance Deposit With Us',
        amount: parseInt(this.props.data.voluntary_advanced_deposit),
      });
      totalPayment -= parseInt(this.props.data.voluntary_advanced_deposit);
      if (this.props.data.dues > 0) {
        payments.push({
          type: 'Dues',
          amount: this.props.data.dues,
        });
        totalPayment = totalPayment + parseInt(this.props.data.dues);
      }
      totalPayment = Math.max(totalPayment, 0);

      if (this.props.data.transaction_status === 'SUCCESS') {
        totalPayment = 0;
        payments = [];
      }

      this.setState({ paymentData: payments });
      this.setState({ totalMoney: totalPayment });
    });

  }

  render() {
    let width = window.innerWidth;
    let mdWidth = width === 768 ? 12 : 9;
    return (
      <React.Fragment>
        {this.state.redirect && <Redirect to="/signin" />}
        {this.state.loading ? (
          <Spinner color="primary" />
        ) : (
          <Col
            className="justify-content-center align-items-center"
            md={mdWidth}
            xs={12}
            style={{ marginBottom: '7%', width: '100%' }}
          >
            <PayBar
              amount={this.state.totalMoney}
              data={this.state.data}
              paymentData={this.state.paymentData}
              notification={this.props.notification}
            />

            {Object.keys(this.state.data).length !== 0 && (
              <HTable data={this.state.data} />
            )}
          </Col>
        )}
      </React.Fragment>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(UserDashboard);
