import React from 'react';
import MUIDataTable from 'mui-datatables';

const options = {
  //Look into onDownload working
  filterType: 'checkbox',
  rowsPerPage: 15,
  responsive: 'scroll',
  downloadOptions: {
    filterOptions: {
      useDisplayedColumnsOnly: false,
      useDisplayedRowsOnly: false,
    },
  },
};

const OurTable = props => {
  return (
    <MUIDataTable
      title={props.title}
      data={props.data}
      columns={props.columns}
      options={
        props.options === undefined || props.options === null
          ? options
          : props.options[0]
      }
    />
  );
};

export default OurTable;
