import React from 'react';
// import Header from 'user/components/Layout/Header';
import Header from 'components/commons/header';
import { Row, Spinner } from 'reactstrap';
import axios from 'axios';
import Footer from 'components/commons/footer';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dashboard from 'user/pages/UserDashboard';
import Transaction from 'user/pages/transaction';
import { Content } from './';
import Profile from 'user/pages/Profile';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

var ls = require('local-storage');
class UserLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      doRedirect: true,
      notActive: false,
      loading: true,
    };

    // props.history.push('/signin');
  }

  componentDidMount() {
    axios
      .get('/api/users/profile', {
        headers: { 'ima-auth-token': ls.get('ima-auth-token') },
      })
      .then(response => {
        if (response.data.status !== 'active') {
          let msg =
            'Your account has been suspended. Contact Admin to resume your account.';
          try {
            this.notificationSystem.addNotification({
              title: 'Login',
              message: msg,
              level: 'error',
            });
          } catch (e) {
            alert(msg);
          }
          setTimeout(() => {
            this.setState({ notActive: true });
          }, 3000);
        } else {
          this.setState({ data: response.data, loading: false });
        }
      })
      .catch(err => {
        this.setState({ doRedirect: true });
      });
  }

  render() {
    let width = window.innerWidth;
    console.log(width !== 768);
    if (this.state.notActive) {
      return <Redirect to="/landing" />;
    } else {
      return (
        <React.Fragment>
          <Content
            fluid
            style={{
              display: 'flex',
              flexDirection: '`colum`',
              minHeight: '100vh',
            }}
          >
            <Header loggedIn />
            <NotificationSystem
              dismissible={false}
              ref={notificationSystem =>
                (this.notificationSystem = notificationSystem)
              }
              style={NOTIFICATION_SYSTEM_STYLE}
            />
            <Row>
              {/* modify margins */}
              <Profile notification={this.notificationSystem} />
              <Switch>
                <Route
                  path="/user/dashboard"
                  component={() => (
                    <Dashboard
                      data={this.state.data}
                      notification={this.notificationSystem}
                      loading={this.state.loading}
                    />
                  )}
                />
                <Route
                  exact={true}
                  path="/user/transactions/:transactionId"
                  component={props => <Transaction />}
                />
                <Redirect from="/user" to="/user/dashboard" />
              </Switch>
            </Row>
            <Footer />
          </Content>
        </React.Fragment>
      );
    }
  }
}

export default UserLayout;
