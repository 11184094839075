import React from 'react';
import HeaderDesk from './headerDesk';
import HeaderMob from './headerMob';

class Header extends React.Component {
  state = {
    flag: true,
  };
  handleMenu = e => {
    e.preventDefault();
    if (this.state.flag) {
      this.setState({ flag: false });
    } else {
      this.setState({ flag: true });
    }
  };
  render() {
    return (
      <header
        id="site-header"
        className="site-header mobile-header-blue header-style-1"
      >
        <HeaderDesk {...this.props} />
        <HeaderMob {...this.props} />
      </header>
    );
  }
}

export default Header;
