import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
// import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  // MdNotificationsActive
} from 'react-icons/md';
import UserImage from 'assets/img/users/user.png';
import { Redirect } from 'react-router-dom';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

// const MdNotificationsActiveWithBadge = withBadge({
//   size: 'md',
//   color: 'primary',
//   style: {
//     top: -10,
//     right: -10,
//     display: 'inline-flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   children: <small>5</small>,
// })(MdNotificationsActive);

var ls = require('local-storage');
class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    Redirect: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();
    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  handleSignOut = () => {
    ls.remove('ima-admin-auth-token');
    this.setState({ Redirect: true });
  };
  render() {
    // const { isNotificationConfirmed } = this.state;

    return (
      <>
        {this.state.Redirect && <Redirect to="/adminsignin" />}
        <Navbar light expand className={bem.b('bg-white')}>
          <Nav navbar className="mr-2">
            <Button outline onClick={this.handleSidebarControlButton}>
              <MdClearAll size={25} />
            </Button>
          </Nav>
          <Nav navbar className={bem.e('nav-right')}>
            <NavItem>
              <NavLink id="Popover1">
                <Avatar
                  onClick={this.toggleUserCardPopover}
                  className="can-click"
                  src={UserImage}
                />
              </NavLink>
              <Popover
                placement="bottom-end"
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.toggleUserCardPopover}
                target="Popover1"
                className="p-0 border-0"
                style={{ minWidth: 250 }}
              >
                <PopoverBody className="p-0 border-dark" style={{border: "1px solid black !important"}}>
                  <UserCard
                    avatar={UserImage}
                    title="ADMIN"
                    // subtitle="sanjaydpatil24@gmail.com"
                    // text="9822520257"
                    className="border-dark"
                  >
                    <ListGroup flush>
                      {/* <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem> */}
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-dark"
                        onClick={this.handleSignOut}
                      >
                        <MdExitToApp /> Signout
                      </ListGroupItem>
                    </ListGroup>
                  </UserCard>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
        </Navbar>
      </>
    );
  }
}

export default Header;
