import React from 'react';
import axios from 'axios';
import { Button } from 'reactstrap';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
class AskQue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = e => {
    e.preventDefault();
    if (
      !this.state.mob ||
      (this.state.mob && this.state.mob.length !== 10) ||
      !this.state.firstName ||
      !this.state.lastName ||
      !this.state.email ||
      !this.state.query
    ) {
      try {
        this.notificationSystem.addNotification({
          title: 'Question',
          message:
            'Mobile no. should be 10 Digit, Email should valid and please enter other fields.',
          level: 'error',
        });
      } catch (err) {
        alert(
          'Mobile no. should be 10 Digit, Email should valid and please enter other fields.',
        );
      }
    } else {
      axios
        .post('/api/users/postFAQ', {
          body: {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            mob: this.state.mob,
            email: this.state.email,
            query: this.state.query,
          },
        })
        .then(response => {
          try {
            this.notificationSystem.addNotification({
              title: 'FAQs',
              message: 'Question sent to Admin! Tune in for updates.',
              level: 'success',
            });
          } catch (err) {
            alert('Question sent to Admin! Tune in for updates.');
          }
          this.setState({
            firstName: '',
            lastName: '',
            mob: '',
            email: '',
            query: '',
          });
        })
        .catch(err => {
          try {
            this.notificationSystem.addNotification({
              title: 'FAQs',
              message:
                'Error processing your Question. Please ask the question again!',
              level: 'error',
            });
          } catch (err) {
            alert(
              'Error processing your Question. Please ask the question again!',
            );
          }
        });
    }
  };
  render() {
    return (
      <section className="wpb_row row-fluid mt-4">
        <div className="container">
          <NotificationSystem
            dismissible={false}
            ref={notificationSystem =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />
          <div className="row">
            <div className="wpb_column column_container col-sm-12 col-md-12">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  {/* <div className="empty_space_12">
                    <span className="empty_space_inner"></span>
                  </div> */}
                  <div
                    role="form"
                    className="wpcf7"
                    id="wpcf7-f1989-p967-o1"
                    lang="en-US"
                    dir="ltr"
                  >
                    <div className="screen-reader-response"></div>
                    <form action="#" method="post" className="wpcf7-form">
                      <div className="row">
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-fname">
                            <input
                              type="text"
                              name="your-fname"
                              value={
                                this.state.firstName ? this.state.firstName : ''
                              }
                              size="40"
                              className="wpcf7-form-control"
                              required
                              placeholder="First Name"
                              onChange={e =>
                                this.setState({ firstName: e.target.value })
                              }
                            />
                          </span>
                        </div>
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-lname">
                            <input
                              type="text"
                              name="your-lname"
                              value={
                                this.state.lastName ? this.state.lastName : ''
                              }
                              size="40"
                              className="wpcf7-form-control"
                              required
                              placeholder="Last Name"
                              onChange={e =>
                                this.setState({ lastName: e.target.value })
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap your-phone">
                            <input
                              required
                              type="text"
                              name="your-phone"
                              size="40"
                              className="wpcf7-form-control"
                              value={this.state.mob ? this.state.mob : ''}
                              placeholder="Phone Number"
                              onChange={e =>
                                this.setState({ mob: e.target.value })
                              }
                            />
                          </span>
                        </div>
                        <div className="col-md-6">
                          <span className="wpcf7-form-control-wrap yourmob">
                            <input
                              type="email"
                              name="your-email"
                              required
                              size="40"
                              className="wpcf7-form-control"
                              placeholder="Email Address"
                              value={this.state.email ? this.state.email : ''}
                              onChange={e =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </span>
                        </div>
                      </div>

                      <div className="contact-mess">
                        <span className="wpcf7-form-control-wrap your-message">
                          <textarea
                            name="your-message"
                            cols="40"
                            rows="10"
                            required
                            className="wpcf7-form-control"
                            placeholder="Add your question here..."
                            value={this.state.query ? this.state.query : ''}
                            onChange={e =>
                              this.setState({ query: e.target.value })
                            }
                          ></textarea>
                        </span>
                      </div>
                      <p className="d-flex align-items-center justify-content-center">
                        <Button
                          type="submit"
                          // value="SUBMIT"
                          className="btn"
                          onClick={this.handleSubmit}
                        >
                          Submit
                        </Button>
                      </p>
                    </form>
                  </div>
                  <div className="empty_space_30 lg-hidden">
                    <span className="empty_space_inner"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AskQue;
