import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
// import * as jws from jws;

function DummyPayment() {

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src =
            'https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js';
        script1.type = "module";
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src = 'https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js';
        script2.noModule = true;
        document.body.appendChild(script2);

        const link1 = document.createElement('link');
        link1.href = 'https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css';
        link1.rel = 'stylesheet';
        document.body.appendChild(link1);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
            document.body.removeChild(link1);
        };
    }, []);
    const [ip, setIP] = useState("");
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        console.log(res.data);
        setIP(res.data.ip);
    };
    useEffect(() => {
    //passing getData method to the lifecycle method
        getData();
    }, []);
    

    // async function encryptAndSignJWSWithHMAC(reqStr, secretKey, clientid) {
    //     const signer = JWS.createSign({ alg: 'HS256', key: secretKey });
    
    //     const jwsHeader = new JWSHeader({
    //         alg: 'HS256',
    //         extraHeaders: { clientid: clientid, }
    //     });
    
    //     const jwsObject = new JWSObject(jwsHeader, new Payload(reqStr));
    //     await jwsObject.sign(signer);
        
    //     return jwsObject.serialize();
    //   }
    
    // async function verifyAndDecryptJWSWithHMAC(encryptedSignedMessage, verificationKey) {
    //     const jwsObject = await JWS.verify(encryptedSignedMessage, verificationKey);
    
    //     const clientId = jwsObject.protectedHeader.clientid;
    //     console.log("clientId = " + clientId);
    
    //     const message = jwsObject.payload.toString();
    //     return message;
    
    // }
    // function hideSpinner() {
    //     document.getElementById('spinner').style.display = 'none';
    // }

    // function showSpinner() {
    //     document.getElementById('spinner').style.display = 'block';
    // }

    var responseHandler = function (response) {

        console.log("responseHandler callback received")


        console.log(response);
    };

    async function makeAPICall() {
        // Define the payload
        try {
            const currentDate = new Date();

            const formattedDate = currentDate.toISOString().split('.')[0] + '+05:30';
            console.log(formattedDate);
            const payload = {
                "orderid": "75",
                "mercid": "UATIMAMSV2",
                "order_date": formattedDate,
                "amount": "1.05",
                "currency": "356",
                "ru": "https://pguatweb.billdesk.io/pgtxnsimulator/v1_2/pageclose",
                "itemcode": "DIRECT",
                "device":{
                    "init_channel": "internet",
                    "ip": ip,
                    "user_agent": window.navigator.userAgent
                }
            };
            
            const res = await axios.get(
                "/api/users/createorderhmac",{
                params: {
                    reqStr: JSON.stringify(payload)
                }}
            )
            const bdorderid = res.data.response.bdorderid;
            const oauthtoken = res.data.response.links[1].headers.authorization;
            
            var flow_config ={
                merchantId:"UATIMAMSV2",
                bdOrderId: bdorderid,
                authToken: oauthtoken,
                childWindow: true,
            }
            console.log(flow_config);
            var config = {
                responseHandler: responseHandler,
                flowConfig: flow_config,
                flowType: "payments"
            };
            window.loadBillDeskSdk(config);
            // const response = await axios.post("https://uat1.billdesk.com/u2/payments/ve1_2/orders/create", res.data, {
            //     headers:{
            //     'content-type': 'application/jose',
            //     'bd-timestamp': formattedDate,
            //     'accept': 'application/jose',
            //     'bd-traceid': traceid
            //     }
            // });
            // console.log(response.data);
        }
        catch (error) {
            console.error('Error:', error);
        }
        // const traceid = "fqwf";
        // // Define the headers
        // const headers = {
        //   'content-type': 'application/jose',
        //   'bd-timestamp': formattedDate,
        //   'accept': 'application/jose',
        //   'bd-traceid': traceid,
        // };
      
        // // Encrypt and sign the payload
        // const encryptedSignedMessage = await encryptAndSignJWSWithHMAC(JSON.stringify(payload), 'kVA210zq9ZZCfo0ai6fTHP5tc6KW6gvR', 'uatimamsv2');
        // console.log("encrypted msg", encryptedSignedMessage);
        // // Make the API call
        // try {
        //   const response = await fetch("https://uat1.billdesk.com/u2/payments/ve1_2/orders/create", {
        //     method: 'POST',
        //     headers: headers,
        //     body: encryptedSignedMessage
        //   });
      
        //   // Handle response
        //   const responseData = await response.json();
        //   console.log(responseData);
        // } catch (error) {
        //   console.error('Error:', error);
        // }
      }

    // const encryptAndSignJWSwithHMAC = (reqStr, secretKey, clientId) => {
    //     try {
    //         const currentDate = new Date();

    //         const formattedDate = currentDate.toISOString().split('.')[0] + 'Z';

    //         const traceid = "fqwf";


    //         const obj = {
    //             "content-type": "application/jose",
    //             "bd-timestamp": formattedDate,
    //             "accept": "application/jose",
    //             "bd-traceid": traceid
    //         }
    //     }
    //     catch(e){
    //         return console.error(e)
    //     }
    // }
    
    async function update(){
        try{
            const res = await axios.get("http://localhost:5002/api/users/update");
            console.log(res);
        }
        catch(e){
            console.log(e);
        }
    }
    return (
        <>
        <div>DummyPayment</div>
        <button onClick={update}>Update</button>
        </>
    )
}

export default DummyPayment;