import Page from 'components/Page';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import FileBase64 from 'react-file-base64';
// MERCHANT ID BDSKUATY

export default function SignUp() {
  const handleSubmit = async () => {
    const payment_amount = rps + '.00';

    try {
      const res = await axios.post('/api/payment/checksum', {
        amount: payment_amount,
        email: email,
        phone: mob,
      });

      axios
        .post('/api/users/register', {
          body: {
            lastName: lastName.toUpperCase(),
            firstName: firstName.toUpperCase(),
            name: firstName.toUpperCase() + ' ' + lastName.toUpperCase(),
            paymentId: res.data.id,
            father_hus_name: fatherHusName.toUpperCase(),
            sex: sex.toUpperCase(),
            birth_date: birth_date.toUpperCase(),
            age: age,
            status,
            add: add.toUpperCase(),
            email: email,
            std: std,
            tel: tel,
            mob: mob,
            medical: medical.toUpperCase(),
            mcRegNo,
            mcRegYear: mcRegYear,
            life_member: life_member,
            life_member_year: life_member_year,
            local_branch: local_branch.toUpperCase(),
            mem_photo: mem_photo,
            ben_photo: ben_photo,
            chq: chq,
            dtd: dtd,
            bank: bank,
            branch: branch.toUpperCase(),
            rps: rps,
            words: words,
            nominee: nominee.toUpperCase(),
            agree_date: agree_date,
            guard: guard.toUpperCase(),
            rela: rela.toUpperCase(),
            addnom: addnom.toUpperCase(),
            e5: e5,
            d2: d2,
            last_ffc_due,
            date_of_registratoin: d2,
            dob_proof,
            ima_life_membership_proof,
            costBreakUp,
          },
        })
        .then(response => {
          window.bdPayment.initialize({
            msg: res.data.msg,
            options: {
              enableChildWindowPosting: true,
              enablePaymentRetry: true,
              retry_attempt_count: 2,
            },
            callbackUrl: 'https://imamssss.com/api/payment/getMsg',
          });
        })
        .catch(err => {
          alert(
            'Issue capturing payment, you will be refunded within 7 working days if debited from your account!\nPlease wait for 24 hours while you get verified by Admin.',
          );
        });
    } catch (err) {
      console.log(err.msg);
    }
  };
  const getAmountFromAge = age => {
    let amount;
    if (age < 30) {
      set_costBreakUp(tableData[0]);
      amount = tableData[0][4];
    } else if (age < 40) {
      set_costBreakUp(tableData[1]);
      amount = tableData[1][4];
    } else if (age < 50) {
      set_costBreakUp(tableData[2]);
      amount = tableData[2][4];
    } else if (age < 55) {
      set_costBreakUp(tableData[3]);
      amount = tableData[3][4];
    } else if (age < 60) {
      set_costBreakUp(tableData[4]);
      amount = tableData[4][4];
    } else amount = 10000;
    return amount;
  };
  const getWordsFromAmount = amount => {
    var a = [
      '',
      'one ',
      'two ',
      'three ',
      'four ',
      'five ',
      'six ',
      'seven ',
      'eight ',
      'nine ',
      'ten ',
      'eleven ',
      'twelve ',
      'thirteen ',
      'fourteen ',
      'fifteen ',
      'sixteen ',
      'seventeen ',
      'eighteen ',
      'nineteen ',
    ];
    var b = [
      '',
      '',
      'twenty',
      'thirty',
      'forty',
      'fifty',
      'sixty',
      'seventy',
      'eighty',
      'ninety',
    ];

    function inWords(num) {
      if ((num = num.toString()).length > 9) return 'overflow';
      var n = ('000000000' + num)
        .substr(-9)
        .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
      if (!n) return;
      var str = '';
      str +=
        n[1] != 0
          ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
          : '';
      str +=
        n[2] != 0
          ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
          : '';
      str +=
        n[3] != 0
          ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
          : '';
      str +=
        n[4] != 0
          ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
          : '';
      str +=
        n[5] != 0
          ? (str != '' ? 'and ' : '') +
            (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]])
          : '';
      return str + 'only';
    }
    let words = inWords(amount);
    words = words[0].toUpperCase() + words.slice(1);
    return words.trim();
  };
  var notificationSystem = useRef();
  const [ct, setct] = useState(0);
  const [lastName, set_lastName] = useState('');
  const [firstName, set_firstName] = useState('');
  const [fatherHusName, set_fatherHusName] = useState('');
  const [sex, set_sex] = useState('Male');
  const [birth_date, set_birth_date] = useState('');
  const [age, set_age] = useState(0);
  const [add, set_add] = useState('');
  const [email, set_email] = useState('');
  const [std, set_std] = useState('');
  const [tel, set_tel] = useState('');
  const [mob, set_mob] = useState('');
  const [medical, set_medical] = useState('');
  const [costBreakUp, set_costBreakUp] = useState([]);
  const [mcRegYear, set_mcRegYear] = useState('');
  const [mcRegNo, set_mcRegNo] = useState('');
  const [life_member, set_life_member] = useState('');
  const [life_member_year, set_life_member_year] = useState('');
  // eslint-disable-next-line
  const [date_of_registeration, set_date_of_registeration] = useState('');
  const [local_branch, set_local_branch] = useState('');
  const [mem_photo, set_mem_photo] = useState(
    'https://imamsssspune.s3.ap-south-1.amazonaws.com/profile/user.png',
  );
  const [ben_photo, set_ben_photo] = useState(
    'https://imamsssspune.s3.ap-south-1.amazonaws.com/profile/user.png',
  );
  const [dob_proof, set_dob_proof] = useState(
    'https://imamsssspune.s3.ap-south-1.amazonaws.com/profile/aadhar.jpg',
  );
  const [ima_life_membership_proof, set_ima_life_membership_proof] = useState(
    'https://imamsssspune.s3.ap-south-1.amazonaws.com/profile/imaLifeMembership.jpg',
  );
  // eslint-disable-next-line
  const [chq, set_chq] = useState('');
  // eslint-disable-next-line
  const [dtd, set_dtd] = useState('');
  // eslint-disable-next-line
  const [bank, set_bank] = useState('');
  // eslint-disable-next-line
  const [branch, set_branch] = useState('');
  const [rps, set_rps] = useState(10000);
  const [words, set_words] = useState(getWordsFromAmount(10000));
  const [nominee, set_nominee] = useState('');
  const [agree_date, set_agree_date] = useState('');
  const [guard, set_guard] = useState('');
  const [rela, set_rela] = useState('');
  const [addnom, set_addnom] = useState('');
  const [e5, set_e5] = useState('');
  // eslint-disable-next-line
  const [status, setStatus] = useState('active');
  // eslint-disable-next-line
  const [d2, set_d2] = useState(new Date().toISOString().slice(0, 10));
  // eslint-disable-next-line
  const [submit, set_submit] = useState('');
  const [toggle, set_toggle] = useState(false);
  const [tableData, setTableData] = useState([[]]);
  // eslint-disable-next-line
  const [last_ffc_due, set_last_ffc_due] = useState(
    '' + (parseInt(new Date().getFullYear()) + 26).toString() + '-04-' + '04',
  );

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://pgi.billdesk.com/payments-checkout-widget/src/app.bundle.js';
    script.async = true;
    document.body.appendChild(script);

    const fetchData = async () => {
      const res = await axios
        .get('/api/users/newUserRegistrationCost')
        .catch(err => {
          alert(
            'Error while fetching Registration Cost, contact Admin your form will not be approved',
          );
          window.location.replace('/');
        });

      let tempTableData = [
        [
          'Below age 30 years ',
          res.data['New User Admission Fees under 30'],
          res.data['New User Advance Fraternity Contribution'],
          res.data['New User Annual Subscription'],
          res.data['New User Admission Fees under 30'] +
            res.data['New User Advance Fraternity Contribution'] +
            res.data['New User Annual Subscription'] +
            res.data['New User CGST under 30'] +
            res.data['New User SGST under 30'],
          res.data['New User CGST under 30'],
          res.data['New User SGST under 30'],
        ],
        [
          'Below age 40 years ',

          res.data['New User Admission Fees under 40'],
          res.data['New User Advance Fraternity Contribution'],
          res.data['New User Annual Subscription'],
          res.data['New User Admission Fees under 40'] +
            res.data['New User Advance Fraternity Contribution'] +
            res.data['New User Annual Subscription'] +
            res.data['New User CGST under 40'] +
            res.data['New User SGST under 40'],
          res.data['New User CGST under 40'],
          res.data['New User SGST under 40'],
        ],
        [
          'Below age 50 years ',
          res.data['New User Admission Fees under 50'],
          res.data['New User Advance Fraternity Contribution'],
          res.data['New User Annual Subscription'],
          res.data['New User Admission Fees under 50'] +
            res.data['New User Advance Fraternity Contribution'] +
            res.data['New User Annual Subscription'] +
            res.data['New User CGST under 50'] +
            res.data['New User SGST under 50'],
          res.data['New User CGST under 50'],
          res.data['New User SGST under 50'],
        ],
        [
          'Below age 55 years ',
          res.data['New User Admission Fees under 55'],
          res.data['New User Advance Fraternity Contribution'],
          res.data['New User Annual Subscription'],
          res.data['New User Admission Fees under 55'] +
            res.data['New User Advance Fraternity Contribution'] +
            res.data['New User Annual Subscription'] +
            res.data['New User CGST under 55'] +
            res.data['New User SGST under 55'],
          res.data['New User CGST under 55'],
          res.data['New User SGST under 55'],
        ],
        [
          'Below age 60 years ',
          res.data['New User Admission Fees under 60'],
          res.data['New User Advance Fraternity Contribution'],
          res.data['New User Annual Subscription'],
          res.data['New User Admission Fees under 60'] +
            res.data['New User Advance Fraternity Contribution'] +
            res.data['New User Annual Subscription'] +
            res.data['New User CGST under 60'] +
            res.data['New User SGST under 60'],
          res.data['New User CGST under 60'],
          res.data['New User SGST under 60'],
        ],
      ];
      setTableData(tempTableData);
    };

    fetchData();

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Form id="myForm">
      <NotificationSystem
        dismissible={false}
        ref={notificationSystem}
        style={NOTIFICATION_SYSTEM_STYLE}
      />
      {(ct === 0 && (
        <Page
          title="Application Form"
          style={{ marginTop: '10px' }}
          breadcrumbs={[{ name: 'Personal Details', active: true }]}
        >
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <h3>
                    <center>Personal Details</center>
                  </h3>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Row>
                      <Col xs={12} sm={12} md={6}>
                        <Label for="exampleLast">Last Name</Label>
                        <Input
                          type="text"
                          onChange={e => set_lastName(e.target.value)}
                          value={lastName}
                          name="surname"
                          required
                        />
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Label for="exampleFirst"> First Name</Label>
                        <Input
                          type="text"
                          onChange={e => set_firstName(e.target.value)}
                          value={firstName}
                          name="firstname"
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col xs={12} md={6}>
                        <Label for="exampleFather">
                          {' '}
                          Father's / Husband's Name
                        </Label>
                        <Input
                          type="text"
                          value={fatherHusName}
                          name="father_hus_name"
                          onChange={e => set_fatherHusName(e.target.value)}
                          required
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Label for="exampleSelect">Sex</Label>
                        <Input
                          type="select"
                          onChange={e => set_sex(e.target.value)}
                          value={sex}
                          name="sex"
                          required
                        >
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col xs={12} md={6}>
                        <Label for="exampleDate">Date of Birth</Label>
                        <Input
                          type="date"
                          value={birth_date}
                          name="birth_date"
                          required
                          onChange={e => {
                            let today = new Date();
                            let [year, month, day] = e.target.value.split('-');
                            year = Number(year);
                            month = Number(month);
                            day = Number(day);

                            let tempAge = today.getFullYear() - year;
                            if (
                              today.getMonth() < month ||
                              (today.getMonth() == month &&
                                today.getDate() < day)
                            ) {
                              tempAge--;
                            }
                            set_age(tempAge);
                            let tempAmount = getAmountFromAge(tempAge);
                            set_rps(tempAmount);
                            set_words(getWordsFromAmount(tempAmount));
                            set_birth_date(e.target.value);
                          }}
                          id="exampledate"
                          placeholder="date placeholder"
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Label for="exampleNumber">Age</Label>
                        <Input
                          type="number"
                          value={age}
                          required
                          name="age"
                          onChange={e => set_age(e.target.value)}
                          id="examplenumber"
                          disabled
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleadd">Address for Correspondence</Label>
                    <Input
                      type="textarea"
                      onChange={e => set_add(e.target.value)}
                      value={add}
                      required
                      name="add"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      type="email"
                      value={email}
                      required
                      name="email"
                      onChange={e => set_email(e.target.value)}
                      // placeholder=""
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplestd">STD code</Label>
                    <Input
                      type="text"
                      value={std}
                      name="std"
                      required
                      onChange={e => set_std(e.target.value)}
                      id="exampleSTD"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampletel">Tel. no.</Label>
                    <Input
                      type="text"
                      value={tel}
                      required
                      name="tel"
                      onChange={e => set_tel(e.target.value)}
                      id="exampletel"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplephone">Mobile no.</Label>
                    <Input
                      type="text"
                      value={mob}
                      required
                      placeholder="Strictly 10 Digit Mobile Number"
                      name="mob"
                      onChange={e => set_mob(e.target.value)}
                      id="examplephone"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplemedical">Name of Medical Council</Label>
                    <Input
                      type="text"
                      required
                      onChange={e => set_medical(e.target.value)}
                      value={medical}
                      name="medical"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Row sm={12}>
                      <Col sm={12} md={6}>
                        <Label for="exampleyear">
                          No. of Medical Council Registration
                        </Label>
                        <Input
                          type="text"
                          value={mcRegNo}
                          required
                          name="mcRegNo"
                          onChange={e => set_mcRegNo(e.target.value)}
                          id="exampleyear"
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <Label for="exampleyear">
                          Year of Medical Council Registration
                        </Label>
                        <Input
                          type="text"
                          value={mcRegYear}
                          required
                          name="mcRegYear"
                          onChange={e => set_mcRegYear(e.target.value)}
                          id="exampleyear"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row sm={12}>
                      <Col sm={12} md={6}>
                        <Label for="examplemem">
                          I.M.A. Life Membership No.
                        </Label>
                        <Input
                          type="text"
                          value={life_member}
                          required
                          name="life_member"
                          onChange={e => set_life_member(e.target.value)}
                          id="examplemem"
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <Label for="examplemem">
                          I.M.A. Life Membership Year
                        </Label>
                        <Input
                          type="text"
                          value={life_member_year}
                          required
                          name="life_member_year"
                          onChange={e => set_life_member_year(e.target.value)}
                          id="examplemem"
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label for="examplelocal">Name of Local Branch</Label>
                    <Input
                      type="text"
                      required
                      onChange={e => set_local_branch(e.target.value)}
                      value={local_branch}
                      name="local_branch"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col xs={12} md={3}>
                        <Label for="exampleFile">Member Photo</Label>
                        <div>
                          <img
                            src={mem_photo}
                            alt="Member"
                            width="150px"
                            height="150px"
                          />
                          <br />
                          <FileBase64
                            multiple={false}
                            value={mem_photo}
                            onDone={files => set_mem_photo(files.base64)}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <Label for="exampleFile">Beneficiary Photo</Label>
                        <div>
                          <img
                            src={ben_photo}
                            alt="Beneficiary"
                            width="150px"
                            height="150px"
                          />
                          <br />
                          <FileBase64
                            multiple={false}
                            value={ben_photo}
                            onDone={files => set_ben_photo(files.base64)}
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <Label for="exampleFile">
                          IMA Life Membership Proof
                        </Label>
                        <div>
                          <img
                            src={ima_life_membership_proof}
                            alt="Address Proof"
                            width="150px"
                            height="150px"
                          />
                          <br />
                          <FileBase64
                            multiple={false}
                            value={ima_life_membership_proof}
                            onDone={files =>
                              set_ima_life_membership_proof(files.base64)
                            }
                          />
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <Label for="exampleFile">
                          Date of Birth Proof (Aadhar / PAN / Passport / LC)
                        </Label>
                        <div>
                          <img
                            src={dob_proof}
                            alt="DOB Proof"
                            width="150px"
                            height="150px"
                          />
                          <br />
                          <FileBase64
                            multiple={false}
                            value={dob_proof}
                            onDone={files => set_dob_proof(files.base64)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </FormGroup>
                  {age < 60 && age > 0 ? (
                    <Button
                      onClick={() => {
                        scrollToTop();
                        setct(ct + 1);
                      }}
                      className="float-right"
                    >
                      Next
                    </Button>
                  ) : (
                    <Button className="float-right" disabled>
                      Not Eligible
                    </Button>
                  )}
                </CardBody>
              </Card>
              <div></div>
            </Col>
          </Row>
        </Page>
      )) ||
        (ct === 1 && (
          <Page
            title="Application Form"
            style={{ marginTop: '10px' }}
            breadcrumbs={[{ name: 'Beneficiary Details', active: true }]}
          >
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h3>
                      <center>BENEFICIARY (NOMINATION) FORM</center>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                      <Label for="examplenom">
                        Name of Beneficiary/Nominee
                      </Label>
                      <Input
                        type="text"
                        value={nominee}
                        name="nominee"
                        required
                        onChange={e => set_nominee(e.target.value)}
                        placeholder="Surname    First Name    Father's / Husband's Name"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampledo">Beneficiary Date of Birth</Label>
                      <Input
                        type="date"
                        value={agree_date}
                        required
                        name="agree_date"
                        onChange={e => set_agree_date(e.target.value)}
                        id="do"
                        placeholder="date placeholder"
                      />
                    </FormGroup>
                    <FormGroup className="form-inline">
                      <Label for="exampleguard">
                        If Minor, Guardian's Name
                      </Label>
                      <Input
                        type="text"
                        value={guard}
                        required
                        name="guard"
                        onChange={e => set_guard(e.target.value)}
                        // placeholder="Surname    First Name    Father's / Husband's Name"
                      />
                      &nbsp; &nbsp;
                      <Label for="examplerela">Relation</Label>
                      <Input
                        type="text"
                        onChange={e => set_rela(e.target.value)}
                        value={rela}
                        required
                        name="rela"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleaddnom">add of Beneficiary</Label>
                      <Input
                        type="textarea"
                        value={addnom}
                        name="addnom"
                        required
                        onChange={e => set_addnom(e.target.value)}
                      />
                    </FormGroup>
                    <div>
                      <Button
                        onClick={() => {
                          scrollToTop();
                          ct !== 0 ? setct(ct - 1) : setct(ct);
                        }}
                        className="float-left"
                      >
                        Previous
                      </Button>
                      <Button
                        onClick={() => {
                          scrollToTop();
                          setct(ct + 1);
                        }}
                        className="float-right"
                      >
                        Next
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Page>
        )) ||
        (ct === 2 && (
          <Page
            title="Application Form"
            style={{ marginTop: '10px' }}
            breadcrumbs={[{ name: 'Declaration', active: true }]}
          >
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h3>
                      <center>DECLARATION</center>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <ol>
                      <li>
                        I, the undersigned, hereby apply for the Membership of
                        IMA Maharashtra State Social Security Scheme
                      </li>
                      <li>
                        I do hereby declare that the above answers are true and
                        that I have withheld no information whatsoever regarding
                        the application
                      </li>
                      <li>
                        Since there is no medical examination while joining the
                        scheme, no benefits will be given to me for 365 days
                        from the date of enrolment as a member (except in
                        accidental cases)
                      </li>
                      <li>
                        I agree to pay yearly amount of FFC demanded as per no.
                        of deaths of members of this scheme +Annual Fraternity
                        contributions
                      </li>
                      <li>
                        I will inquire with office, if I do not receive the
                        demand noce of F.F.C. Latest by 10th May every year
                      </li>
                      <li>
                        I further agree to abide by the condions laid down in
                        the constitution & amendments approved by the General
                        Body of IMA-MS-SSS, from me to me.{' '}
                      </li>
                      <li>
                        I agree that Dispute of any nature whatsoever will be
                        subjected to Pune Jurisdiction Only.{' '}
                      </li>
                      <li>
                        <FormGroup className="form-inline">
                          <Label for="example5">
                            I am a life member of INDIAN MEDICAL ASSOCIATION
                          </Label>
                          &nbsp; &nbsp;
                          <Input
                            type="text"
                            required
                            onChange={e => set_e5(e.target.value)}
                            value={e5}
                            name="e5"
                          />
                          &nbsp; &nbsp;
                          <Label for="example5">
                            BRANCH OF MAHARASHTRA STATE.
                          </Label>
                        </FormGroup>
                      </li>
                      <li>
                        I shall inform any change of add/Phone/Mobile/Email /
                        Beneficiary or Nominee immediately on my Letterhead.
                      </li>
                      <li>
                        I shall quote IMA-MS-SSS Membership No. in every
                        correspondence.
                      </li>
                    </ol>

                    <FormGroup check className="form-inline">
                      <Label for="exampledtd">Date</Label>
                      &nbsp; &nbsp;
                      <Input
                        type="date"
                        value={d2}
                        name="d2"
                        required
                        // onChange={e => set_d2(e.target.value)}
                        id="exampled2"
                        placeholder="date placeholder"
                        disabled
                      />
                      &nbsp; &nbsp;
                      <Label check>
                        <Input
                          type="checkbox"
                          id="checkbox1"
                          // value="checked"
                          onClick={() =>
                            toggle === false
                              ? set_toggle(true)
                              : set_toggle(false)
                          }
                          checked={toggle}
                        />{' '}
                        I Agree
                      </Label>
                    </FormGroup>
                    <div style={{ marginTop: '10px' }}>
                      <Button
                        onClick={() => {
                          scrollToTop();
                          ct !== 0 ? setct(ct - 1) : setct(ct);
                        }}
                        className="float-left"
                      >
                        Previous
                      </Button>
                      <Button
                        onClick={() => {
                          toggle
                            ? setct(ct + 1)
                            : notificationSystem !== undefined &&
                              notificationSystem.current !== undefined &&
                              notificationSystem.current.addNotification !==
                                undefined
                            ? notificationSystem.current.addNotification({
                                title: 'Terms',
                                message: 'Please select the terms.',
                                level: 'info',
                              })
                            : alert('Please select the terms');
                        }}
                        className="float-right"
                      >
                        Next
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Page>
        )) ||
        (ct === 3 && (
          <Page
            title="Application Form"
            style={{ marginTop: '10px' }}
            breadcrumbs={[{ name: 'Payment Structure', active: true }]}
          >
            <Row>
              <Col>
                <Card className="mb-3">
                  <CardHeader>
                    <h4>
                      {' '}
                      Admission Payment Structure As Per Age &emsp; &emsp;
                      W.E.F.1st April 2020 To 31st March 2021
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <Card body>
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Sr. No.</th>
                                <th>
                                  Amount for Payment No. for various Age Group
                                </th>
                                <th>Fraternity Contribution Deposit</th>
                                <th>
                                  Admission Fees<b>*</b>
                                </th>
                                <th>
                                  2 year Annual Subscription<b>*</b>
                                </th>
                                <th>SGST on * Fields</th>
                                <th>CGST on * Fields</th>
                                <th>Total Rs.</th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((value, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{value[0]}/-</td>
                                  <td>{value[2]}/-</td>
                                  <td>{value[1]}/-</td>
                                  <td>{value[3]}/-</td>
                                  <td>{value[5]}/-</td>
                                  <td>{value[6]}/-</td>
                                  <td>{value[4]}/-</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          <div>
                            <Button
                              onClick={() =>
                                ct !== 0 ? setct(ct - 1) : setct(ct)
                              }
                              className="float-left"
                            >
                              Previous
                            </Button>
                            <Button
                              onClick={() => setct(ct + 1)}
                              className="float-right"
                            >
                              Next
                            </Button>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Page>
        )) ||
        (ct === 4 && (
          <Page
            title="Application Form"
            style={{ marginTop: '10px' }}
            breadcrumbs={[{ name: 'Payment Details', active: true }]}
          >
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h3>
                      <center>PAYMENT DETAILS</center>
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup className="form-inline">
                      <Label for="examplerps">Rupees </Label>
                      <Input
                        type="number"
                        value={rps}
                        name="rps"
                        // onChange={e => set_rps(e.target.value)}
                        id="examplerps"
                        disabled
                      />
                      &nbsp; &nbsp;
                      <Label for="examplewords">(in words) Rs. {words}</Label>
                    </FormGroup>
                    <div>
                      <Button
                        onClick={() => (ct !== 0 ? setct(ct - 1) : setct(ct))}
                        className="float-left"
                      >
                        Previous
                      </Button>
                      <Button
                        className="float-right"
                        value={submit}
                        name="submit"
                        id="submit"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Page>
        ))}
    </Form>
  );
}
