import React, { Component } from 'react';

class BilldeskPolicy extends Component {
  render() {
    return (
      <div className="container" style={{ marginTop: '1.4rem' }}>
        <h2>Payments, Refunds & Cancellation</h2>
        <h4>Payments</h4>
        <h6>
          <ol type="1">
            <li>Online Payments can be done by: BillDesk</li>
            Online Payment: Card payment and Net Banking Payment via BillDesk
            Payment Gateway can be done at Online Payment link on Make Payment
            page.
            <li>
              Problem with any payment: Please talk to us on{' '}
              <a href="tel:+919699652255">+919699652255</a> or email us at{' '}
              <a href="mailto:imamsssspune@gmail.com">imamsssspune@gmail.com</a>
            </li>
          </ol>
        </h6>

        <h4>Refunds & Cancellation</h4>
        <h6>
          <ol type="1">
            <li>
              Refunds / Cancellation initiated will be credited to the
              account/card from where the transaction had initiated.
            </li>
          </ol>
        </h6>

        <br />
        <hr />
        <br />

        <h2>Terms & Conditions</h2>
        <h6>
          To avail the services offered at{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> , you must
          agree to the following terms and conditions:
          <br />
          <br />
          Please read the term and conditions carefully. While using any current
          or future services offered by{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> , whether or
          not included in the{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a>. website, you
          will abide by these Terms & conditions the guidelines and conditions
          applicable to such service or business.
          <br />
          <br />
          <h4>Privacy Policy</h4>
          Please review our Privacy Policy, which also governs your visit to{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a>, to fully
          understand our practices.
          <br />
          <br />
          <h4>Electronic Communication</h4>
          When you visit <a href="https://www.imamssss.com">
            www.imamssss.com
          </a>{' '}
          or send e-mails to us on{' '}
          <a href="mailto:imamsssspune@gmail.com">imamsssspune@gmail.com</a>,
          you are communicating with us electronically. By communicating with
          us, you consent to receive communication from us electronically. We
          will communicate with you by e-mail or by posting notices on our
          website. You agree that all agreements, notices, disclosures, and
          other communications that we provide to you electronically satisfy any
          legal requirement that such communication be in writing.
          <br />
          <br />
          <h4>Prices</h4>
          All prices posted on this website are subject to change without
          notice. Prices prevailing at commencement of placing the order will
          apply. Posted prices do not include convenience fee. In case there are
          any additional charges or taxes the same will be mentioned on the
          website.
          <br />
          <br />
          <h4>Payment</h4>
          Online Payments can be done by:
          <br />
          Online Payment: Card payment and Net Banking Payment via BillDesk
          Payment Gateway can be done at Online Payment link on Make Payment
          page.
          <br />
          <br />
          <h4>Refund</h4>
          Refunds initiated will be credited to the account/card from where the
          transaction had initiated.
        </h6>

        <br />
        <hr />
        <br />

        <h2>License and Website Access</h2>
        <h6>
          <h4>General:</h4>
          <a href="https://www.imamssss.com">www.imamssss.com</a> grants you a
          limited license to access and make personal use of this website and
          not to download (other than page caching) or modify it, or any portion
          of it, except with express written consent of{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a>
          <br />
          <br />
          <h4>No license for commercial sale:</h4>
          This license does not include any resale or commercial use of this
          website or its content; any collection and use of any product listing,
          description, or pricing; copying of account information for the
          benefit of another merchant; or any use of data mining, or similar
          data gathering and extraction tools.
          <br />
          <br />
          <h4>No reproduction:</h4>
          This website or any portion of this website may not be reproduced,
          duplicated, copies, sold, visited, or otherwise exploited for any
          commercial purpose without express written consent of{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a>
          <br />
          <br />
          <h4>No framing:</h4>
          You may not frame or utilize framing technologies to enclose any
          trademark, logo, or other proprietary information (including images,
          text, page layout, or form) of{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> without the
          express written consent.
          <br />
          <br />
          <h4>Metatags:</h4>
          You may not use any metatags or any other 'hidden text' utilizing
          Supreme Enterprises name or trademarks without the express written
          consent of <a href="https://www.imamssss.com">www.imamssss.com</a>.
          Any unauthorized use terminates the permission or license granted by{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a>
        </h6>

        <br />
        <hr />
        <br />

        <h2>Reviews, Comments, Communications, and other content</h2>
        <h6>
          <h4>Nature of content:</h4>
          Visitors to <a href="https://www.imamssss.com">
            www.imamssss.com
          </a>{' '}
          may post content and other communications; and submit suggestions,
          ideas, comments, questions or other information, as long as the
          content is not illegal, obscene, threatening, defamatory, invasive of
          privacy, infringing of intellectual property rights to otherwise
          injuries to third party or objectionable and does not consist of or
          contains software virus, political campaigning, commercial
          solicitation, mass mailing or any form of spam.
          <br />
          <br />
          <h4>False information:</h4>
          You may not use false email address, impersonate any person or entity,
          or otherwise mislead as to the origin of a card or other content.
          <a href="https://www.imamssss.com">www.imamssss.com</a> reserves the
          right (but not the obligation) to remove or edit such content but does
          not regularly review posted contents.
          <br />
          <br />
          <h4>Rights Granted:</h4>
          If you do post content or submit material and unless we indicate
          otherwise, you grant{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> a
          non-exclusive, royalty free, perpetual, irrevocable, and fully
          sub-licensed right to use, reproduce, modify, adapt, publish,
          translate, create derivative work from, distribute, and display such
          content throughout the world in any media. You grant{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> the right to
          use the name that you submit in connection with such content if{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a>
          chooses.
          <br />
          <br />
          <h4>Right Owned:</h4>
          You represent and warrant that you own all the rights or otherwise or
          control all of the rights to the content that you post; that the
          content is accurate; that the use of the content to supply does not
          violate this policy and will not cause injury to any person or entity
          and that you will indemnify{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> for all claims
          resulting from the content you supply.{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> has the right
          but not the obligation to monitor and edit or remove any activity or
          content. <a href="https://www.imamssss.com">www.imamssss.com</a> takes
          no responsibility and assumes no liability for any content posted by
          you or any third party.
          <br />
          <br />
          <h4>Site Policies, Modification, and Severability</h4>
          Please review our other policies. We reserve the right to make changes
          to our website, policies, and these Terms and Conditions at any time.
          If any of these conditions shall be deemed invalid, void, or for any
          reason unenforceable, that condition shall be deemed severable and
          will not affect the validity and enforceability of any remaining
          conditions.
        </h6>

        <br />
        <hr />
        <br />

        <h2>Intellectual Property Rights</h2>
        <h4>Copyright Protection:</h4>
        <h6>
          All content included on this site, such as text, graphics, logos,
          button icons, audio clips, digital downloads, data compilations and
          software, is the property of{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> and protected
          by the Indian Copyright law. The compilation of all the content on
          this site is the exclusive property if{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> and protected
          by Indian Copyright law. All software used in this site is the
          property of <a href="https://www.imamssss.com">www.imamssss.com</a>{' '}
          and is protected under the Indian Copyright law.
        </h6>
        <br />
        <br />
        <h4>Trademarks:</h4>
        <h6>
          <ol type="i">
            <li>Protected Marks:</li>
            <p>
              <a href="https://www.imamssss.com">www.imamssss.com</a>, and other
              marks indicated on{' '}
              <a href="https://www.imamssss.com">www.imamssss.com</a> website
              are registered trademarks of{' '}
              <a href="https://www.imamssss.com">www.imamssss.com</a>
            </p>
            <li>Protected Graphics:</li>
            <p>
              All <a href="https://www.imamssss.com">www.imamssss.com</a>{' '}
              graphics, logos, page headers, button icons, scripts and service
              names are trademarks or trade dress of{' '}
              <a href="https://www.imamssss.com">www.imamssss.com</a>.{' '}
              <a href="https://www.imamssss.com">www.imamssss.com </a>trademarks
              and trade dress may not be used in connections with any product or
              service that is not of{' '}
              <a href="https://www.imamssss.com">www.imamssss.com</a>.
            </p>
          </ol>
        </h6>

        <br />
        <hr />
        <br />

        <h2>Governing Law and Jurisdiction</h2>
        <h6>
          These terms and conditions will be construed only in accordance with
          the laws of India. In respect of all matters/disputes arising out of,
          in connection with or in relation to these terms and conditions or any
          other conditions on this website, only the competent Courts at Mumbai,
          Mumbai shall have jurisdiction, to the exclusion of all other courts.
        </h6>

        <br />
        <hr />
        <br />

        <h2>Disclaimer of warranties and Limitation of Liability</h2>
        <h6>
          AVAILABLE" BASIS INDIAN MEDICAL ASSOCIATION MAHARASHTRA STATE MAKES NO
          REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
          THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR
          PRODUCTS INCLUED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF
          THIS SITE IS AT YOUR SOLE RISK.
          <br />
          <br />
          TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW,{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> DOES NOT
          WARRANT THAT THE SITE, ITS SERVERS, OR EMAIL SENT FROM{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> ARE FREE OF
          VIRUS OR OTHER HARMFUL COMPONENTS.{' '}
          <a href="https://www.imamssss.com">www.imamssss.com</a> WILL NOT BE
          LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE,
          INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE
          AND CONSEQUENTIAL DAMAGES.
        </h6>

        <br />
        <hr />
        <br />

        <h2>Privacy Policy</h2>
        <h6>
          We maintain full privacy of your personal information shared with us.
          We never misuse any of your personal information. Our privacy policy
          is as given below:
        </h6>
        <ol typi="1">
          <li>
            <h4>Collection and Sharing of customer information: </h4>
          </li>
          <h6>
            We collect information from you only after you agree to provide it.
            Any information you give us is held with care and security. This
            information is collected primarily to ensure that we ship your
            products without any hassles.
            <br />
            <br />
            We may collect your title, name, gender, email address, postal
            address, delivery address (if different), telephone number, mobile
            number, fax number, payment details or bank account details. All
            this information is processed securely for your protection.
            <br />
            <br />
            Your actual order details may be stored with us and you may access
            this information by logging into your account on the Site. You
            undertake to treat the personal access data confidentially and not
            make it available to unauthorized third parties.
            <br />
            <br />
            Under no circumstances do we rent, trade or share your personal
            information that we have collected with any other company for their
            marketing purposes without your consent. We reserve the right to
            communicate your personal information to any third party that makes
            a legally-compliant request for its disclosure.
          </h6>
          <br />
          <br />
          <li>
            <h4>Intellectual Property Rights:</h4>
          </li>
          <h6>
            All content included on this site, such as text, graphics, logos,
            button icons, images and software, is the property of INDIAN MEDICAL
            ASSOCIATION MAHARASHTRA STATE . All content on the Site that is not
            the property of INDIAN MEDICAL ASSOCIATION MAHARASHTRA STATE is used
            with permission.
          </h6>
        </ol>
      </div>
    );
  }
}

export default BilldeskPolicy;
