import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import PageNotFound from 'assets/img/404.jpeg';

class NotFound extends React.Component {
  render() {
    return (
      <div>
        <img style={{ width: '100%' }} src={PageNotFound} alt="Not Found" />
        <Row className="d-flex align-items-center justify-content-center">
          <Link to="/">
            <Button color="primary" style={{ margin: '10%' }}>
              Go Home{' '}
            </Button>
          </Link>
        </Row>
      </div>
    );
  }
}
export default NotFound;
