import { STATE_LOGIN } from 'components/AuthForm';
import GAListener from 'components/GAListener';
import {
  EmptyLayout,
  LayoutRoute,
  MainLayout,
  LandingLayout,
  UserLayout,
  RegisterLayout,
  AdminLoginLayout,
} from 'components/Layout';
// import Landing from './landing';
import AuthLayout from 'components/Layout/AuthLayout';
import AnnouncementLayout from './components/Layout/AnnouncementLayout';
import ForgotPasswordLayout from 'components/Layout/ForgotPassword';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from 'admin/ProtectedRoute';
import UserProtectedRoute from './user/pages/UserProtectedRoute';
import NotFound from './landing/NotFound';
import './styles/reduction.scss';
import DummyPayment from './user/pages/DummyPayment';
// import ForgotPassword from './pages/ForgotPassword';

const AdminDashboard = React.lazy(() => import('./admin/DashboardPage'));
const FAQPage = React.lazy(() => import('./admin/FAQPage'));
const ReportPage = React.lazy(() => import('./admin/ReportPage'));
const DemandNotice = React.lazy(() => import('./admin/DemandNotice'));
const DownloadData = React.lazy(() => import('./admin/DownloadData'));
const UsersPage = React.lazy(() => import('./admin/UsersTable'));
const DeathsPage = React.lazy(() => import('./admin/DeathsTable'));
const FAQsPage = React.lazy(() => import('./admin/FAQsTable'));
const PaymentInfo = React.lazy(() => import('./admin/PaymentInfo'));
const NotificationsPage = React.lazy(() => import('./admin/MassNotifications'));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={LandingLayout}
            />
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />

            <UserProtectedRoute
              path="/user"
              layout={EmptyLayout}
              component={UserLayout}
            />
            <LayoutRoute
              path="/dummypayment"
              layout={EmptyLayout}
              component={DummyPayment}
            />
            <LayoutRoute
              path="/landing"
              layout={EmptyLayout}
              component={LandingLayout}
            />
            <LayoutRoute
              path="/signup"
              layout={EmptyLayout}
              component={RegisterLayout}
            />
            <LayoutRoute
              path="/signin"
              layout={EmptyLayout}
              component={AuthLayout}
            />
            <LayoutRoute
              path="/sanjeevani"
              layout={EmptyLayout}
              component={AnnouncementLayout}
            />
            <LayoutRoute
              path="/forgotPassword"
              layout={EmptyLayout}
              component={ForgotPasswordLayout}
            />
            <LayoutRoute
              path="/adminsignin"
              layout={EmptyLayout}
              component={AdminLoginLayout}
            />

            {/* {window.location.href.split('/')[1] !== 'admin' ? (
              <Redirect to="/" />
            ) : (
              <></>
            )} */}
            <Route path="/admin">
              <MainLayout breakpoint={this.props.breakpoint}>
                <React.Suspense fallback={<PageSpinner />}>
                  <Switch>
                    <Route
                      exact
                      path="/admin/report"
                      //component={ReportPage}
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/report"
                          component={ReportPage}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/users"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/users"
                          component={UsersPage}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/deaths"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/deaths"
                          component={DeathsPage}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/faqs"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/faqs"
                          component={FAQPage}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/demandNotice"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/demandNotice"
                          component={DemandNotice}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/FAQs"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/FAQs"
                          component={FAQsPage}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/paymentInfo"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/paymentInfo"
                          component={PaymentInfo}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/admin/downloadData"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/downloadData"
                          component={DownloadData}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/admin/notifications"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin/notifications/"
                          component={NotificationsPage}
                        />
                      )}
                    />

                    <Route
                      exact
                      path="/admin"
                      render={() => (
                        <ProtectedRoute
                          exact
                          path="/admin"
                          component={AdminDashboard}
                        />
                      )}
                    />
                    <Redirect from="/admin/" to="/error" />
                  </Switch>
                </React.Suspense>
              </MainLayout>
            </Route>
            <EmptyLayout>
              <LayoutRoute layout={EmptyLayout} component={LandingLayout} />
            </EmptyLayout>
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
