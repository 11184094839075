import React from 'react';
import PropTypes from 'utils/propTypes';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { Link } from 'react-router-dom';
import { Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';
import { Button } from '@material-ui/core';

import Avatar from '../../../components/Avatar';

const UserCard = ({
  avatar,
  avatarSize,
  title,
  email,
  phone,
  text,
  children,
  className,
  ...restProps
}) => {
  return (
    <Card inverse {...restProps}>
      <CardBody
        style={{ backgroundColor: '#7cb342' }}
        className="d-flex justify-content-center align-items-center flex-column"
      >
        <Avatar src={avatar} height={150} width={120} className="mb-2" />
        <CardTitle className="mb-3">{title}</CardTitle>
        <CardSubtitle className="mb-1">
          <EmailIcon />
          <small>{email}</small>
        </CardSubtitle>
        <CardSubtitle>
          <PhoneIcon />
          <small>{phone}</small>
        </CardSubtitle>
        <CardSubtitle>
          <Button
            component={Link}
            to="/user/dashboard"
            variant="contained"
            color="secondary"
            style={{ marginTop: '20px', color: 'white' }}
          >
            Home
          </Button>
        </CardSubtitle>
        {/* <CardText>
          <small>{text}</small>
        </CardText> */}
      </CardBody>
      {/* {children} */}
    </Card>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

UserCard.defaultProps = {
  avatarSize: 80,
};

export default UserCard;
