import React from 'react';
import regForm from 'assets/forms/SSS_Registeration_Form_2.pdf';
import updateForm from 'assets/forms/Nomination_Form_Updated.pdf';
import { Link } from 'react-router-dom';

var ls = require('local-storage');
class HeaderMob extends React.Component {
  state = {
    flag: false,
  };
  handleMenu = e => {
    e.preventDefault();
    if (this.state.flag) {
      this.setState({ flag: false });
    } else {
      this.setState({ flag: true });
    }
  };
  handleClick = () => {
    ls.remove('ima-auth-token');
  };
  render() {
    return (
      <div className="header_mobile">
        <div className="mlogo_wrapper clearfix">
          <a href="/" style={{ textDecoration: 'none' }}>
            <div className="mobile_logo">
              <h3 style={{ color: 'whitesmoke' }}>
                Indian Medical Association
              </h3>
              <span className="text-light">
                Maharashtra State - Social Security Scheme
              </span>
            </div>
          </a>
          <div id="mmenu_toggle">
            <button onClick={this.handleMenu}></button>
          </div>
        </div>
        <div className="mmenu_wrapper">
          <div
            className="mobile_nav"
            style={{
              display: this.state.flag ? 'block' : 'none',
            }}
          >
            <ul id="menu-main-menu" className="mobile_mainmenu">
              <li>
                <a href="/landing">Home</a>
              </li>
              <li>
                <a href="/landing#AboutUs">About Us</a>
              </li>
              <li>
                <a href="/landing/committee">Committee</a>
              </li>
              <li>
                <a href="/landing#Bye_Laws">By Laws</a>
              </li>
              <li>
                <a href="/landing/members">Members</a>
              </li>
              <li>
                <a href="/landing#FAQs">FAQs</a>
              </li>

              <li className="menu-item-has-children">
                <a>Membership Registration</a>
                <ul className="sub-menu">
                  <li>
                    {/* <a href="/signup">Online Form</a> */}
                    <a href="#">Online Form</a>
                  </li>
                  <li>
                    <a
                      href="https://imamsssspune.s3.ap-south-1.amazonaws.com/IMA+MS+SSS+Updated+Form+%26+Information+sheet.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Offline Form
                    </a>
                    {/*<a
                        href={regForm}
                        // target="_blank"
                        download
                      >
                      </a>*/}
                  </li>
                  <li>
                    <Link to={updateForm} target="_blank" download>
                      Update Nominee
                    </Link>
                    {/*<a href={updateForm} download>
                      Update Nominee
                    </a>*/}
                  </li>
                </ul>
              </li>
              <li>
                {/* <Button
                  onClick={this.props.history.push('/landing/contact-us')}
                > */}
                <a href="/landing/contact-us">Contact Us</a>

                {/* Contact Us */}
                {/* </Button> */}
              </li>
              <li className="menu-item-has-children">
                <a href="#"> Publications </a>
                <ul className="sub-menu">
                  <li>
                    <a
                      href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/SSS+Annual+Report+22-23.pdf"
                      target="blank"
                    >
                      Annual Report 22-23 (AGM 2023)
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/SSS+Annual+Report+21-22.pdf"
                      target="blank"
                    >
                      Annual Report 21-22 (AGM 2022)
                    </a>
                  </li>
                  <li>
                    <a href="/landing/annual_report_21">
                      Annual Report 20-21 (AGM 2021)
                    </a>
                  </li>
                  <li>
                    <a href="/landing/annual_report_20">
                      Annual Report 19-20 (AGM 2020)
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/annualreport19.zip"
                      target="_blank"
                    >
                      Annual Report 18-19 (AGM 2019)
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="search-cart-box">
              {(this.props.loggedIn && (
                <a href="/landing">
                  <button
                    onClick={this.handleClick}
                    className="btn_landing2"
                    style={{ height: 'auto' }}
                  >
                    Sign Out
                  </button>
                </a>
              )) || (
                  <a href="/signin">
                    <button className="btn_landing2" style={{ height: 'auto' }}>
                      Login
                    </button>
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HeaderMob;
