import React from 'react';

class ByLaws extends React.Component {
  render() {
    return (
      <section
        className="wpb_row row-fluid section-padd bg-second row-has-fill"
        id="Bye_Laws"
      >
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-6">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <h2 className="custom_heading text-light">By Laws</h2>
                  <div className="wpb_text_column wpb_content_element  text-light">
                    <div className="wpb_wrapper">
                      <p>Pl inquire for any latest change in rules/by laws</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-6">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="wpb_text_column wpb_content_element text-light">
                    <div className="wpb_wrapper">
                      <a href={require('./Resolution.pdf')} download>
                        <h4 className="cv-download">
                          Click Below to Download
                          <br />
                          <span className="text-primary font12">Download</span>
                        </h4>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ByLaws;
