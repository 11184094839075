import React from 'react';

class BelowContact extends React.Component {
  render() {
    return (
      <section className="wpb_row row-fluid section-padd no-bot">
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-12 col-md-9">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="section-head ">
                    <h6>
                      <span>Pune Office</span>
                    </h6>
                    <h5 className="section-title">
                      IMA Maharashtra State Social Security Scheme Office No.
                      408, Dr Nitu Mandke IMA House 992 Shukrawar Peth Tilak
                      Road, Swargate, Pune 411002
                    </h5>
                  </div>

                  <div className="empty_space_12">
                    <span className="empty_space_inner"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="wpb_column column_container col-sm-12 col-md-8">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="wpb_text_column wpb_content_element contact-info">
                    <div className="wpb_wrapper">
                      <p>
                        <a href="mailto:imamsssspune@gmail.com">
                          <i className="fa fa-envelope">
                            {' '}
                            imamsssspune@gmail.com
                          </i>
                        </a>
                        <a href="tel:020-24464771">
                          <i className="fa fa-phone-square">Tel 020-24464771</i>
                        </a>
                        <a href="tel:020-24430042">
                          <i className="fa fa-phone-square">
                            {' '}
                            Tel 020-24430042
                          </i>
                        </a>
                        <a href="tel:+919699652255">
                          <i className="fa fa-phone-square">
                            {' '}
                            Mobile: 9699652255
                          </i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="wpb_column column_container col-sm-12 col-md-4">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <div className="wpb_text_column wpb_content_element socials text-right md-hidden sm-hidden">
                    <div className="wpb_wrapper">
                      <p>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-facebook-official">fb</i>
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-twitter">tw</i>
                        </a>
                        <a href="#" target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-pinterest">pr</i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default BelowContact;
