import React from 'react';
// import { Row, Col } from 'reactstrap';
class Footer extends React.Component {
  render() {
    return (
      <footer xs={12} id="site-footer" className="site-footer bg-second">
        <div className="main-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div id="media_image-1" className="widget widget_media_image">
                  <h3 style={{ color: 'whitesmoke' }}>
                    Indian Medical Association
                  </h3>
                </div>
                <div
                  id="custom_html-1"
                  className="widget_text widget widget_custom_html"
                >
                  <div className="textwidget custom-html-widget">
                    <p>Social Security Scheme</p>
                    <div className="ot-socials bg-white">
                      <a target="_blank" href="#" rel="noopener noreferrer">
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a target="_blank" href="#" rel="noopener noreferrer">
                        <i className="fa fa-twitter"></i>
                      </a>
                      <a target="_blank" href="#" rel="noopener noreferrer">
                        <i className="fa fa-pinterest-p"></i>
                      </a>
                      <a target="_blank" href="#" rel="noopener noreferrer">
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 float-center">
                <section
                  id="custom_html-3"
                  className="widget_text widget widget_custom_html padding-left"
                >
                  {/* <h4 className="widget-title"></h4> */}
                  <div className="textwidget custom-html-widget">
                    <ul className="padd-right">
                      <li>
                        <a href="/landing#IMA_Home">Home</a>
                      </li>
                      <li>
                        <a href="/landing#AboutUs">About Us</a>
                      </li>
                      <li>
                        <a href="/landing/committee">Committee</a>
                      </li>
                      <li>
                        <a href="/landing/members">Members</a>
                      </li>
                      <li>
                        {/* eslint-disable-next-line  */}
                        <a href="#">Member Registration</a>
                      </li>
                      <li>
                        <a href="/landing#Bye_Laws">By Laws</a>
                      </li>
                      <li>
                        <a href="/landing/contact-us">Contact Us</a>
                      </li>
                      <li>
                        <a href="/landing/billdesk-policy">
                          Payment Gateway Policy on Website
                        </a>
                      </li>
                    </ul>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="footer-copyright">
                  © 2020 Made with ❤ By{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.coep.org.in/sds/"
                  >
                    SDS
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-nav text-right mobile-center">
                  <ul id="footer-menu" className="none-style">
                    <li>
                      <a href="#">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/landing#FAQs">FAQs</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a id="back-to-top" href="#" className="show"></a>
      </footer>
    );
  }
}

export default Footer;
