import React from 'react';
import annualReport from 'assets/forms/Annual_Report_2020.pdf';
import accountStatement from 'assets/forms/Statement_of_Accounts_2020.pdf';
import auditReport from 'assets/forms/Audit_Report_Letter_2020.pdf';
import { Button, Grid, Typography } from '@material-ui/core';
import { Document, Page } from 'react-pdf';

class Report20 extends React.Component {
  render() {
    return (
      <Grid container direction="row" style={{ marginTop: '1em', marginBottom: '1em', textAlign: 'center'  }}>
        <Grid container xs={12}>
          <Typography
            className="section-title"
            style={{ color: 'darkblue', width: 'max-content', margin: 'auto' }}
            variant="h5"
            gutterBottom
          >
            DOWNLOADABLES FROM THE YEAR 2020
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ margin: '1em' }}
        >
          <Button
            variant="contained"
            color="primary"
            href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/Audit_Report_Letter_2020.2a6cd88f.pdf"
            style={{ color: 'white' }}
            target="_blank"
          >
            Preamble to Annual Report AGM 2020
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ margin: '1em' }}
        >
          {/* <Document file="assets/forms/Annual_Report_2020.pdf">
            <Page pageNumber={1} />
          </Document> */}

          <Button
            variant="contained"
            color="primary"
            href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/Annual_Report_2020.c7c9a4e5.pdf"
            style={{ color: 'white' }}
            target="_blank"
          >
            Annual Report AGM 2020
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ margin: '1em' }}
        >
          <Button
            variant="contained"
            color="primary"
            href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/Statement_of_Accounts_2020.d723dc4a.pdf"
            target="_blank"
            style={{ color: 'white' }}
          >
            Audited Statement of Accounts AGM 2020
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default Report20;
