import { Content } from 'components/Layout';
import React from 'react';
import ForgotPassword from 'pages/ForgotPassword';
import Header from 'components/commons/header';
import Footer from 'components/commons/footer';

class ForgotPasswordLayout extends React.Component {
  render() {
    return (
      <main>
        <Content fluid>
          <Header />
          <ForgotPassword />
          <Footer />
        </Content>
      </main>
    );
  }
}

export default ForgotPasswordLayout;
