import { Content } from 'components/Layout';
import React from 'react';
import SignUp from 'pages/SignUp';
import Header from 'components/commons/header';
import Footer from '../commons/footer';
class RegisterLayout extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <main>
        <Header />
        <Content fluid>
          <SignUp />
        </Content>
        <Footer />
      </main>
    );
  }
}

export default RegisterLayout;
