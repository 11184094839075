import React from 'react';
import UserCard from '../components/Card/UserCard';
import axios from 'axios';
import user1Image from 'assets/img/users/user.png';
import { Col, Spinner } from 'reactstrap';
// import NotificationSystem from 'react-notification-system';
// import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
const ls = require('local-storage');

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      mob: '',
      name: '',
      data: {},
      loading: true,
    };
  }
  componentDidMount() {
    // this.fetchProfile();
    axios
      .get('/api/users/profile', {
        headers: { 'ima-auth-token': ls.get('ima-auth-token') },
      })
      .then(response => {
        this.setState({ data: response, loading: false });
      })
      .catch(err => {
        let msg = 'Error fetching profile! Please refresh the page.';
        try {
          this.props.notification.addNotification({
            title: 'Profile',
            message: msg,
            level: 'error',
          });
        } catch (e) {
          console.log(e);
          alert(msg);
        }
      });

    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Col
        className=" d-flex align-items-center flex-column"
        xs={12}
        md={2}
        lg={3}
        xl={3}
      >
        {this.state.loading ? (
          <Spinner color="primary" />
        ) : (
          <UserCard
            avatar={user1Image}
            title={this.state.data.data.name}
            email={
              this.state.data.data !== null &&
              this.state.data.data !== undefined
                ? this.state.data.data.email
                : ''
            }
            phone={
              this.state.data.data !== null &&
              this.state.data.data !== undefined
                ? this.state.data.data.mob
                : ''
            }
            style={{
              marginTop: '10%',
              height: 300,
              width: 320,
            }}
          />
        )}
      </Col>
      // </Page>
    );
  }
}
export default Profile;
