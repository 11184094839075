import React from 'react';
import regForm from 'assets/forms/SSS_Registeration_Form_2.pdf';
import updateForm from 'assets/forms/Nomination_Form_Updated.pdf';
import { Link } from 'react-router-dom';

import notice1 from 'assets/forms/Notice_1_to_607.pdf';
import notice2 from 'assets/forms/Notice_2_608_to_7802.pdf';
//import annual_report19 from 'assets/forms/annual_report19.zip';
var ls = require('local-storage');
class HeaderDesk extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      doRedirect: false,
    };
  }
  handleClick = () => {
    ls.remove('ima-auth-token');
  };
  render() {
    return (
      <>
        <div className="main-header md-hidden sm-hidden">
          <div className="main-header-top">
            <div className="container">
              <div className="row">
                <div className="col-md-6 align-self-center">
                  <div id="site-logo">
                    <a href="/" style={{ textDecoration: 'none' }}>
                      <div
                        id="header_name"
                        className="d-flex justify-content-start"
                      >
                        <h6 style={{ color: 'whitesmoke' }}>
                          Indian Medical Association {'\n'}
                          <h6 style={{ color: 'whitesmoke' }}>
                            Maharashtra State{' '}
                          </h6>
                          <h4>Social Security Scheme</h4>
                        </h6>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="col-md-6 align-self-center">
                  <div className="col-media-body col-media-middle">
                    <ul className="info-list info_on_right_side fright">
                      <li>
                        <span>
                          Address:
                          <br />{' '}
                          <strong style={{ whiteSpace: 'pre-wrap' }}>
                            <span style={{ whiteSpace: 'nowrap' }}>
                            Office No. 408, Dr Nitu Mandke IMA House
                            </span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                            992 Shukrawar Peth Tilak Road, Swargate,
                            </span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                            Pune 411002
                            </span>
                          </strong>
                        </span>{' '}
                      </li>
                      <li>
                        <span>
                          Call:
                          <br />{' '}
                          <strong className="font-size18">
                            <span style={{ whiteSpace: 'nowrap' }}>
                              020-24464771
                            </span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                              020 24430042
                            </span>
                            <span style={{ whiteSpace: 'nowrap' }}>
                              9699652255
                            </span>
                          </strong>
                        </span>{' '}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-header md-hidden sm-hidden">
          <div className="main-header-bottom">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="header-mainnav">
                    <div className="search-cart-box fright">
                      <div className="row">
                        <div className="col-md-6">
                          <a href="/signup">
                            <button
                              onClick={this.handleClick}
                              className="btn_landing2"
                              style={{
                                height: 'auto',
                                background: 'yellow',
                                color: 'black',
                              }}
                            >
                              Enrolment
                            </button>
                          </a>
                        </div>
                        <div className="col-md-6">
                          {(this.props.loggedIn && (
                            <a href="/landing">
                              <button
                                onClick={this.handleClick}
                                className="btn_landing2"
                                style={{ height: 'auto' }}
                              >
                                Sign Out
                              </button>
                            </a>
                          )) || (
                            <a href="/signin">
                              <button
                                className="btn_landing2"
                                style={{ height: 'auto' }}
                              >
                                Login
                              </button>
                            </a>
                          )}
                        </div>
                      </div>
                    </div>

                    <div id="site-navigation" className="main-navigation fleft">
                      <ul id="primary-menu" className="menu">
                        <li>
                          <a href="/landing#IMA_Home">Home</a>
                        </li>
                        <li>
                          <a href="/landing#AboutUs">About Us</a>
                        </li>
                        <li>
                          <a href="/landing/committee">Committee</a>
                        </li>
                        <li>
                          <a href="/landing#Bye_Laws">By Laws</a>
                        </li>
                        <li>
                          <a href="/landing/members">Members</a>
                        </li>
                        <li>
                          <a href="/landing#FAQs">FAQs</a>
                        </li>

                        <li className="menu-item-has-children">
                          <a href="#"> Member Registration</a>
                          <ul className="sub-menu">
                            <li>
                              <a href="/signup">Online Form</a>
                              {/*<a href="#">Online Form</a>*/}
                            </li>
                            <li>
                              <a
                                href="https://imamsssspune.s3.ap-south-1.amazonaws.com/IMA%2BMS%2BSSS%2BUpdated%2BForm%2B%26%2BInformation%2Bsheet.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Offline Form
                              </a>
                              {/*<a
                                  href={regForm}
                                  // target="_blank"
                                  download
                                >
                                </a>*/}
                            </li>
                            <li>
                            <a
                                href="https://imamsssspune.s3.ap-south-1.amazonaws.com/Sanjeevani-IMA+MS+SSS+Form+29.02.2024.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Sanjeevani Membership Form
                              </a>
                            </li>
                            <li>
                              <Link to={updateForm} target="_blank" download>
                                Change Nominee
                              </Link>
                              {/*<a href={updateForm} download>
                                Update Nominee
                              </a>*/}
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="/landing/contact-us">Contact Us</a>
                        </li>
                        <li className="menu-item-has-children">
                          <a href="#"> Publications </a>
                          <ul className="sub-menu">
                            <li>
                              <a
                                href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/SSS+Annual+Report+22-23.pdf"
                                target="blank"
                              >
                                Annual Report 22-23 (AGM 2023)
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/SSS+Annual+Report+21-22.pdf"
                                target="blank"
                              >
                                Annual Report 21-22 (AGM 2022)
                              </a>
                            </li>
                            <li>
                              <a href="/landing/annual_report_21">
                                Annual Report 20-21 (AGM 2021)
                              </a>
                            </li>
                            <li>
                              <a href="/landing/annual_report_20">
                                Annual Report 19-20 (AGM 2020)
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/annualreport19.zip"
                                target="_blank"
                              >
                                Annual Report 18-19 (AGM 2019)
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default HeaderDesk;
