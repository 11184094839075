import React from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import componentQueries from 'react-component-queries';
import OurTable from 'components/mui_table';
import { Col, Row } from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import 'styles/reduction.scss';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
// import NotificationSystem from 'react-notification-system';
// import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';
const ls = require('local-storage');

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
const getColor = data=>{
  if(data.title !== 'Status') return 'parent';
  switch(data.value){    
    case "SUCCESS": return 'green';
    case "PENDING": return 'yellow';
    case "ERROR":
    case "FAILURE": return 'red';
    default:return 'initial'
  }
}
const options = {
  rowsPerPage: 15,
  responsive: 'scroll',
  downloadOptions: {
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true,
    },
  },
  selectableRows: 'none',
};

class Transaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentDidMount() {
    var data = [];
    const [transactionId] = window.location.href.split("/").slice(-1)
    
    if(!transactionId)return;
    axios
      .get(`/api/payment/transaction/${transactionId}`)
      .then(response => {   
       const info = response.data.data
       console.log(info)
       if(!info){
         throw new Error("Data not valid")
       }
       const rows = [
        {
          title:"Transaction ID",
          value:info.txnRefNo
        },
        {
          title:"Status",
          value:info.status
        },
         {
            title:"Amount",
            value: info.amount ? '\u20B9 ' + info.amount :  'Not Available'
         },
         {
           title:"Date/Time",
          value: info.txnDate || ''
         },
         {
          title:"Email",
         value:info.email || ''
        },
        {
          title:"Phone Number",
         value:info.phone || ''
        },
       ]
      
        this.setState({
          data: rows,
        });
      })
      .catch(error => {
        setTimeout(()=>{
          window.location.href = "/"
        },2000)
        let msg = 'Error fetching Data. Pleae try again!';
        try {
          this.notificationSystem.addNotification({
            title: 'Transaction',
            message: msg,
            level: 'error',
          });
        } catch (e) {
          alert(msg);
        }
      });
  }

  render() {
    let width = window.innerWidth;
    let mdWidth = width === 768 ? 12 : 9;
    return (
      <Col style={{margin:'4% 2% 4% 2%'}}>
      <NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
        <Row className="justify-content-center align-items-center">
            {this.state?.data?.length ? <h4>Transaction Status</h4> :<></>}
        </Row>

        <Row className="justify-content-center align-items-center">
          
      <Table style={{ maxWidth:600 }} aria-label="transaction status">
        
        <TableBody>
          {this.state.data.map((row) => (
           
              <StyledTableRow key={row.title} >
              <StyledTableCell component="th" scope="row">
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="right" style={{backgroundColor:getColor(row)}}>
               
                  {row.value}
                
                </StyledTableCell>
              </StyledTableRow>
          
          ))}
        </TableBody>
      </Table>

         
        </Row>
        </Col>
      
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(Transaction);
