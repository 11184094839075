import { Content } from 'components/Layout';
import React from 'react';
import SignIn from 'pages/SignIn';
import Header from 'components/commons/header';
import Footer from 'components/commons/footer';

class AuthLayout extends React.Component {
  render() {
    return (
      <main>
        <Content fluid>
          <Header />
          <SignIn />
          <Footer />
        </Content>
      </main>
    );
  }
}

export default AuthLayout;
