import React from 'react';
import axios from 'axios';

import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import PasswordField from 'material-ui-password-field';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyles from '../styles/signin';
import { useState, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

function AdminSignIn(props) {
  var ls = require('local-storage');

  var classes = useStyles();

  var notificationSystem = useRef();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [redirectDash, doRedirectDash] = useState(false);

  const handleChange = name => event => {
    if (name === 'username') setUsername(event.target.value);
    else if (name === 'password') setPassword(event.target.value);
  };

  const authenticate = e => {
    e.preventDefault();
    axios
      .post('/api/admins/auth', {
        body: {
          username,
          pass: password,
        },
      })
      .then(response => {
        ls('ima-admin-auth-token', response.data.token);
        doRedirectDash(true);
      })
      .catch(err => {
        let msg = 'Incorrect Credentials!';
        try {
          notificationSystem.current.addNotification({
            title: 'Login',
            message: msg,
            level: 'error',
          });
        } catch (e) {
          alert(msg);
        }
      });
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{ width: 'auto', margin: '3%', backgroundColor: 'transparent' }}
    >
      {redirectDash === true && <Redirect to="/admin" />}
      <div className={classes.formContainer}>
        <NotificationSystem
          dismissible={false}
          ref={notificationSystem}
          style={NOTIFICATION_SYSTEM_STYLE}
        />
        <div className={classes.form}>
          <React.Fragment>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Admin Sign In
              </Typography>
              <form className={classes.form2} noValidate>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={8}>
                      <h6>Admin ID</h6>
                      <TextField
                        autoComplete="userid"
                        name="userid"
                        variant="outlined"
                        required
                        onChange={handleChange('username')}
                        fullWidth
                        id="uid"
                        autoFocus
                        // variant="outlined"
                      />
                      {/* <FormHelperText>User ID</FormHelperText> */}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={8}>
                      <h6>Password</h6>
                      <PasswordField
                        autoComplete="password"
                        name="password"
                        required
                        fullWidth
                        onChange={handleChange('password')}
                        id="password"
                      />
                      {/* <FormHelperText>Password</FormHelperText> */}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                    sm={4}
                    xs={6}
                  >
                    <Button
                      onClick={authenticate}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.submit}
                    >
                      Sign In
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(AdminSignIn);
