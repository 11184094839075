import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Document, Page } from 'react-pdf';

class Report21 extends React.Component {
  render() {
    return (
      <Grid
        container
        direction="row"
        style={{ marginTop: '1em', marginBottom: '1em', textAlign: 'center' }}
      >
        <Grid container xs={12}>
          <Typography
            className="section-title"
            style={{ color: 'darkblue', width: 'max-content', margin: 'auto' }}
            variant="h5"
            gutterBottom
          >
            DOWNLOADABLES FROM THE YEAR 2021
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          justify="center"
          alignItems="center"
          style={{ margin: '1em' }}
        >
          {/* <Document file="assets/forms/Annual_Report_2020.pdf">
            <Page pageNumber={1} />
          </Document> */}

          <Button
            variant="contained"
            color="primary"
            href={
              'https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/SSS+Annual+Report.pdf'
            }
            style={{ color: 'white' }}
            target="_blank"
          >
            Annual Report AGM 2021
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default Report21;
