import { Content } from 'components/Layout';
import React from 'react';
import Landing from '../../landing';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

class LandingLayout extends React.Component {
  componentDidMount() {
    const url = new URL(window.location.href);
    const status = url.search?.substring(9);
    if (status !== '') {
      if (status === 'SUCCESS') {
        this.notificationSystem.current.addNotification({
          title: 'Payment',
          message:
            'Payment done succesfully. Please wait for 24 hours for payment confirmation.',
          level: 'success',
        });
      } else {
        this.notificationSystem.current.addNotification({
          title: 'Payment',
          message: 'Error occured in payment. Please contact IMA frontdesk.',
          level: 'error',
        });
      }
    }
  }

  notificationSystem = React.createRef();

  render() {
    return (
      <>
        <main style={{ padding: '0', margin: '0' }}>
          <Content fluid>
            <Landing />
          </Content>
        </main>
        <NotificationSystem
          dismissible={false}
          ref={this.notificationSystem}
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </>
    );
  }
}

export default LandingLayout;
