import React from 'react';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import notice1 from 'assets/forms/Notice_1_to_607.pdf';
import notice2 from 'assets/forms/Notice_2_608_to_7802.pdf';
import notice3 from 'assets/forms/SSS inner pages.pdf';
import { Button } from '@material-ui/core';



class NewUpdates extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      info: 'Updates from IMA-MS-SSS will be displayed here!',
      isHovered: true,
    };
    //this.handleHover = this.handleHover.bind(this);
  }
  // componentDidMount() {
  //   axios
  //     .get('/api/admins/fetchUpdate')
  //     .then(response => {
  //       this.setState({
  //         info: response.data.information,
  //       });
  //     })
  //     .catch(err => alert(err));
  // }
  // handleHover() {
  //   this.setState(prevState => ({
  //     isHovered: !prevState.isHovered,
  //   }));
  // }
  render() {
    //const btnClass = this.state.isHovered ? 'blinking' : 'nonBlinking';
    return (
      <div
        style={{
          textAlign: 'center',
          backgroundColor: '#00387f',
        }}
      >
        {/*
        <a href={notice3} target="_blank" download style={{ color: 'yellow' }}>
          Download Annual Report 
        </a>{' '}
        {' '}
        <a href={notice1} target="_blank" download style={{ color: 'red' }}>
          Download Demand Notice 1-607
        </a>{' '}
        |{' '}
        <a href={notice2} target="_blank" download style={{ color: 'red' }}>
          Download Demand Notice 608-7802
        </a>{' '}
        */}
        {/* <a href="https://imamsssspune.s3.ap-south-1.amazonaws.com/PublicationDocuments/AGM_2021_Notice.pdf" style={{ color: 'yellow' }} download> */}
          {/* Click here to download AGM 2021 Notice. */}
        {/* </a> */}
        <br /> 
        <Button variant='contained' size='large' href="/signin" color='white' style={{color: 'red', fontWeight: 'bold'}}>
        Click here to pay FFC.
        </Button>
        <br />
        <br />
        <a href="https://imamsssspune.s3.ap-south-1.amazonaws.com/Sanjeevani-IMA+MS+SSS+Form+29.02.2024.pdf" style={{ color: 'white' }} target='_blank'>
        Sanjeevani Membership Form
        </a>
        
        <br />
        <a href="/sanjeevani" style={{ color: 'yellow' }}>
          New Social Security Scheme (Sanjeevani)
        </a>
        <br />
        
      </div>
    );
  }
}

export default NewUpdates;
