import React from 'react';

class AboutTabs extends React.Component {
  render() {
    return (
      <div className="project-filter" data-column="2">
        <div id="filters" className="cat-filter">
          {/* <a href="#" data-filter=".audit-assurance" className="filter-item">
            IMAMS
          </a>
          <a href="#" data-filter=".strategic-planning" className="filter-item">
            AIMS
          </a> */}
        </div>
        <div id="projects" className="project-grid projects row "></div>
      </div>
    );
  }
}

export default AboutTabs;
