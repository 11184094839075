import React from 'react';

class SSS extends React.Component {
  state = {
    showName: 'Show More',
    flag: false,
  };
  showMoreButtonForSSS = e => {
    e.preventDefault();
    if (this.state.flag) {
      this.setState(prev => ({
        flag: false,
        showName: 'Show More',
      }));
    } else {
      this.setState(prev => ({
        flag: true,
        showName: 'Show Less',
      }));
    }
  };
  render() {
    return (
      <section className="wpb_row row-fluid">
        <div className="container">
          <div className="row">
            <div className="wpb_column column_container col-sm-12">
              <div className="column-inner">
                <div className="wpb_wrapper">
                  <h2>
                    EVERYTHING YOU WANTED TO KNOW ABOUT SOCIAL SECURITY SCHEME{' '}
                  </h2>
                  <div className="wpb_text_column wpb_content_element">
                    <div className="wpb_wrapper">
                      <p>
                        32 years ago, the think tank of IMA, Maharashtra State
                        formulated a scheme “IMA - MS - Social Security Scheme”.
                        Generally, we all have LIC Policies, primarily to cover
                        the risk of premature/untimely death & reduction in tax
                        liabilities. The similarity between LIC & the Social
                        Security Scheme ends there. The scheme covers the risk
                        of premature death of the member. The member has to
                        contribute for 25 years and later only pay the annual
                        subscription of Rs. 200/- only(this may marginally
                        change in future) &amp; he will be a continuous member
                        of the scheme till his / her death, with incremental
                        beneficiary amount with growth of the Scheme. Since the
                        number of members in SSS keep rising, the Doctor, who
                        continues in the Scheme is also monetarily
                        benefitted—even after his retirement from SSS after 25
                        years’ contributions of FFC--, though he DOES NOT
                        contribute FFC then. The money collected in the SSS is
                        invested in financial institutions and formulas allowed
                        by the Charity Commissioner. - The scheme was launched
                        on 1st October 1990--Effective membership as on 5th Jan.
                        2022: 6610 In the year 2021-22 the nominees of each of
                        expired members were paid Rs. 9,25,000/- within few days
                        of getting information of the death and on receiving
                        minimal documents.
                      </p>
                    </div>
                  </div>
                  <div className="empty_space_30"></div>
                  <h4>HOW DOES THE SCHEME WORK?</h4>
                  <div className="wpb_text_column wpb_content_element">
                    <div className="wpb_wrapper">
                      <p>
                        <b>Eligibility :</b> Life member of IMA from Maharashtra
                        State upto the age of 60 years.
                        <br />
                        <b>Rules for benefit : </b>Benefit of Fraternity
                        Contribution of the scheme is available after completion
                        of one year of membership of IMA - MS - S.S.S. The
                        nominee of the member is entitled for such benefits if
                        death of member occurs in accident even within one year
                        of joining the Scheme. Currently, a doctor wishing
                        enrollment pays Rs. 12,000/- as Advance Fraternity
                        Contribution(AFC), which is returned (without any
                        interest) upon death. The admission fee is paid
                        according to the age bracket (see the table on back
                        page). The date of registration shall be date of
                        acceptance of the documents and contributions, as per
                        the schedule, and receiving payment, cash or cheque /
                        DDsubject to realization. Incomplete documents will
                        result in delay in enrollment. In addition to FFC every
                        year, annual membership fee of Rs. 200/-(towards admin
                        expenses)is be paid by all the members. Retired members
                        need to pay only Annual fees of Rs 200/(which may change
                        marginally in future) -Each year, demand note is sent to
                        every member. Rs. 200/- per death that had occurred in
                        the last year will be demanded at the end of each year,
                        in April. This money is known as “<b>Fraternity</b> Fund
                        Contribution” (FFC).Out of this, Rs. 140/- is paid to
                        the nominee of the deceased member & Rs. 60/- ploughsto
                        Corpus of SSS. The interest on this corpus takes care of
                        the FFC payment of the doctors who have retired after 25
                        years in the Scheme, who are not needed to contribute
                        thereafter.
                        <b>
                          <b>
                            All the nominees of deceased members will be paid
                            death claim accordingly to calculations.
                          </b>
                        </b>
                      </p>
                    </div>
                  </div>
                  <div
                    id="showmoresss"
                    style={{ display: this.state.flag ? 'block' : 'none' }}
                  >
                    <div className="empty_space_30"></div>
                    <h4>REQUIREMENTS FOR SSS MEMBERSHIP</h4>
                    <div className="wpb_text_column wpb_content_element">
                      <div className="wpb_wrapper">
                        {/* <p> */}
                        <ol>
                          <li>
                            A prescribed form, duly completed, with endorsement
                            of the Hon. Secretary / President of local branch
                            that you are a Life Member of IMA, Certified copy of
                            Life Membership certificate issued by IMA HQ.
                          </li>
                          <li>
                            Age proof - Certified copy of school / College
                            leaving certificate / Passport / LIC policy 1st page
                            /Aadhar card etc.—any one
                          </li>
                          <li>
                            Demand Draft / Cheque-Payable at par in Pune, of the
                            appropriate amount.
                          </li>
                          <li>
                            Name of nominee. If more nominees, refer to rules.
                            Dispute of any nature whatsoever would be triable by
                            the Courts of Pune Jurisdiction only.
                          </li>
                          <li>
                            Passport size photo to be pasted in photo space—of
                            both, member and nominee.
                          </li>
                        </ol>
                        {/* </p> */}
                        <h6>
                          <b>
                            Admission Payment Structure As Per Age W.E.F. 1st
                            April 2022
                          </b>
                        </h6>
                        {/* <p> */}
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th scope="col">Sr. No.</th>
                              <th scope="col">
                                Amount for Payment As per the Age Groups
                              </th>
                              <th scope="col">
                                Fraternity Distribution Deposit
                              </th>
                              <th scope="col">Admission Fees *</th>
                              <th scope="col">2 X Annual Subscription *</th>
                              <th scope="col">GST on columns *</th>
                              <th scope="col">Grand Total Rs.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th scope="row">1</th>
                              <td>Below age 30 years</td>
                              <td>12,000/-</td>
                              <td>1000/-</td>
                              <td>400/-</td>
                              <td>252/-</td>
                              <td>13,652/-</td>
                            </tr>
                            <tr>
                              <th scope="row">2</th>
                              <td>30 yr complete to 40 yr</td>
                              <td>12,000/-</td>
                              <td>2000/-</td>
                              <td>400/-</td>
                              <td>432/-</td>
                              <td>14,832/-</td>
                            </tr>
                            <tr>
                              <th scope="row">3</th>
                              <td>40 yr complete to 50 yr</td>
                              <td>12,000/-</td>
                              <td>3000/-</td>
                              <td>400/-</td>
                              <td>612/-</td>
                              <td>16,012/-</td>
                            </tr>
                            <tr>
                              <th scope="row">4</th>
                              <td>50 yr complete to 55 yr</td>
                              <td>12,000/-</td>
                              <td>10,000/-</td>
                              <td>400/-</td>
                              <td>1872/-</td>
                              <td>24,272/-</td>
                            </tr>
                            <tr>
                              <th scope="row">5</th>
                              <td>55 yr complete to 60 yr *</td>
                              <td>12,000/-</td>
                              <td>20,000/-</td>
                              <td>400/-</td>
                              <td>3672/-</td>
                              <td>36,072/-</td>
                            </tr>
                          </tbody>
                        </table>
                        {/* </p> */}
                      </div>
                    </div>

                    <div className="empty_space_30"></div>
                    <div className="wpb_wrapper">
                      <h3>ANNUAL FFC PAYMENT SCHEDULE</h3>
                      <p>
                        The notices of FFC will be dispatched latest by 15th
                        April to all members. If one does not get the notice by
                        10th May, he has to inquire with the office. FFC amount
                        must reach the office latest by31st May. Excuse of not
                        receiving the notice from office will not be
                        entertained.Late fee from 1st June to 31st August is Rs.
                        100 and from 1st Aug to 30th Sept, Rs 300. Final notice
                        by regd. AD will be sent in the month of August.
                        Non-payment on 1st October will lead to automatic
                        deletion and the member will have to go through extra
                        payment and exercise of revival of membership. Every
                        member must inquire with the office if the notice is not
                        received by 20th April. (Payment dues may be notified by
                        sms, whatsapp, email and similar means).
                      </p>
                    </div>
                    <div className="empty_space_30"></div>
                    <div className="wpb_wrapper">
                      <h3>VOLUNTARY DEPOSIT FOR FFC AND ANNUAL SUBSCRIPTION</h3>
                      <p>
                        We accept ADVANCE DEPOSIT towards FFC and ANNUAL
                        SUBSCRIPTION in multiples of Rs. 5000/- (Member can pay
                        Rs. 5000/-, 10,000/-, 15,000/- etc.) in advance. The
                        office will deduct the outstanding payment every year
                        and send the statement of their balance deposits. This
                        is totally VOLUNTARY. You may opt/may not opt to pay in
                        advance. <b>This amount will not earn any interest.</b>{' '}
                        In case of the death of a member, the balance of the
                        deposit will be refunded to the nominee along with the
                        death benefit amount. This will spare members from the
                        annual exercise to pay FFC in time and hanging sword of
                        deletion.
                      </p>
                    </div>
                    <div className="empty_space_30"></div>
                    <div className="wpb_wrapper">
                      <h3>
                        REVIVAL OF DELETED MEMBERSHIP - Maximum upto 3 yrs.
                      </h3>
                      {/* <p> */}
                      <ol>
                        <li>
                          Such deleted but desirous member will have to fill up
                          a new application form & submit it with xerox copies
                          of age proof & IMA Life membership certificate along
                          with Bank Draft of proper amount.
                        </li>
                        <li>
                          They will have to pay
                          <ul>
                            <li>
                              Rs. 12,000/- Advance Fraternity Fund as of now
                              (Revisable)
                            </li>
                            <li>
                              Admission Fee as per age group as on date of
                              re-application
                            </li>
                            <li>
                              All defaulting years’ FFC to be paid with Rs.
                              300/- late fees of each year
                            </li>
                          </ul>
                          Please note that as per today’s age, even if the
                          member has exceeded 60 years age, his membership will
                          be revived. Desirous members to revive their
                          membership, please confirm the amounts to be paid,
                          telephonically / postally with office or
                          Chairman/Secretary of SSS..
                        </li>
                        <li>
                          On revival, the member will be considered to be a
                          continuous member of the scheme from the date he had
                          originally joined. He will be entitled for the death
                          claim benefit without waiting for 365 days. His total
                          contributions, paid from beginning, will be counted &
                          he needs to pay total 25 FFC from the date of
                          originaljoiningi.e. his seniority is maintained.
                          Revival is permitted ONCE ONLY.
                        </li>
                        <li>
                          The Deleted members of more than 3 FFC defaults, can
                          join the scheme as New members, however the age limit
                          of 60 years will be applicable and they will become
                          beneficiary after 365 days. Their previous
                          contributions will not be counted, they will have to
                          pay total new 25 contributions of FFC.
                        </li>
                      </ol>
                      {/* </p> */}
                    </div>
                    <div></div>
                  </div>
                  <button
                    className="btn_landing"
                    style={{ float: 'right' }}
                    onClick={this.showMoreButtonForSSS}
                    id="showMoreBtnforsss"
                  >
                    {this.state.showName}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default SSS;
