import React from 'react';
import axios from 'axios';

import { Grid, Typography, Button, TextField } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
// import PasswordField from 'material-ui-password-field';
import { withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import useStyles from '../styles/signin';
import { useState, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { NOTIFICATION_SYSTEM_STYLE } from 'utils/constants';

function ForgotPassword() {
  var classes = useStyles();
  var notificationSystem = useRef();
  const [sssno, setsssno] = useState('');
  const [mobileNumber, setmobileNumber] = useState('');
  const [redirectSignin, doRedirectSignin] = useState(false);

  const handleChange = name => event => {
    if (name === 'sssno') setsssno(event.target.value);
    else if (name === 'mobileNumber') setmobileNumber(event.target.value);
  };

  const signin = e => {
    e.preventDefault();
    doRedirectSignin(true);
  };

  const ForgotPassword = e => {
    e.preventDefault();
    axios
      .post('/api/users/forgotPassword', {
        body: {
          sssno: sssno,
          mobileNumber: mobileNumber,
        },
      })
      .then(response => {
        let msg = 'New Login Credentials sent to registered mobile number.';
        try {
          notificationSystem.current.addNotification({
            title: 'Login',
            message: msg,
            level: 'success',
          });
        } catch (e) {
          alert(msg);
        }
        setTimeout(() => {
          doRedirectSignin(true);
        }, 5000);
      })
      .catch(err => {
        let msg = 'Invalid Mobile Number.';
        try {
          notificationSystem.current.addNotification({
            title: 'Login',
            message: msg,
            level: 'error',
          });
        } catch (e) {
          alert(msg);
        }
      });
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{ width: 'auto', margin: '3%', backgroundColor: 'transparent' }}
    >
      {redirectSignin === true && <Redirect to="/signin" />}
      <NotificationSystem
        dismissible={false}
        ref={notificationSystem}
        style={NOTIFICATION_SYSTEM_STYLE}
      />
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <form className={classes.form2} noValidate>
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={8}>
                      <h6>SSS Number</h6>
                      <TextField
                        autoComplete="userid"
                        name="userid"
                        variant="outlined"
                        required
                        onChange={handleChange('sssno')}
                        fullWidth
                        id="uid"
                        autoFocus
                        // variant="outlined"
                      />
                      {/* <FormHelperText>User ID</FormHelperText> */}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justify="center"
                    spacing={2}
                  >
                    <Grid item xs={12} sm={8}>
                      <h6>Mobile Number</h6>
                      <TextField
                        autoComplete="mobileNumber"
                        name="mobileNumber"
                        variant="outlined"
                        required
                        fullWidth
                        onChange={handleChange('mobileNumber')}
                        id="mobileNumber"
                      />
                      {/* <FormHelperText>Password</FormHelperText> */}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    justify="center"
                    sm={4}
                    xs={6}
                  >
                    <Button
                      onClick={ForgotPassword}
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={classes.submit}
                    >
                      submit
                    </Button>
                  </Grid>

                  <Grid
                    container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                    sm={12}
                    xs={12}
                  >
                    <Button
                      onClick={signin}
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign In
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(ForgotPassword);
